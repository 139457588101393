import './index.css'

import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { store } from './app/store'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@emotion/react'
import { createTheme } from '@mui/material'
import colors from 'styles/colors'
import { deDE } from '@mui/x-data-grid'
import { ToastContainer } from 'react-toastify'

/* Roboto font for MUI */
// import '@fontsource/roboto/300.css'
// import '@fontsource/roboto/400.css'
// import '@fontsource/roboto/500.css'
// import '@fontsource/roboto/700.css'

/* Inter font */
import '@fontsource/inter' // Defaults to weight 400
import '@fontsource/inter/100.css' // Specify weight
import '@fontsource/inter/200.css' // Specify weight
import '@fontsource/inter/300.css' // Specify weight
import '@fontsource/inter/400.css' // Specify weight
import '@fontsource/inter/500.css' // Specify weight
import '@fontsource/inter/600.css' // Specify weight
import '@fontsource/inter/700.css' // Specify weight
import '@fontsource/inter/800.css' // Specify weight
import '@fontsource/inter/900.css' // Specify weight

/* Custom MUI theme for MUI components */
const MUItheme = createTheme(
  {
    palette: {
      mode: 'light',
      primary: {
        main: colors.green[400],
        light: colors.green[300],
        dark: colors.green[500],
        contrastText: colors.white,
      },
      secondary: {
        light: colors.yellow[300],
        main: colors.yellow[400],
        dark: colors.yellow[500],
        contrastText: colors.gray[800],
      },
    },
    components: {
      MuiInput: {
        defaultProps: {
          disableUnderline: true,
        },
      },
    },
  },
  deDE,
)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={MUItheme}>
          <ToastContainer closeOnClick={true} />
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
