import FamulenzLink from 'components/control/Link/FamulenzLink'
import FamulenzGridLayout from 'components/layout/GridLayout/FamulenzGridLayout/FamulenzGridLayout'
import { type IFamulenzGridLayoutElement } from 'components/layout/GridLayout/FamulenzGridLayoutElement/FamulenzGridLayoutElement'
import React from 'react'
import { type Layouts } from 'react-grid-layout'
import { BsSpeedometer2 } from 'react-icons/bs'

export default function Dashboard(): React.JSX.Element {
  const layouts: Layouts = {
    xl: [
      { i: 'internships', x: 0, y: 0, w: 5, h: 6 },
      { i: 'calender', x: 5, y: 0, w: 5, h: 6 },
      { i: 'statistics', x: 0, y: 6, w: 10, h: 5 },
      { i: 'internship_offers', x: 0, y: 11, w: 5, h: 5 },
    ],
    lg: [
      { i: 'internships', x: 0, y: 0, w: 5, h: 6 },
      { i: 'calender', x: 5, y: 0, w: 5, h: 6 },
      { i: 'statistics', x: 0, y: 6, w: 10, h: 5 },
      { i: 'internship_offers', x: 0, y: 11, w: 5, h: 5 },
    ],
    md: [
      { i: 'internships', x: 0, y: 0, w: 4, h: 6 },
      { i: 'calender', x: 4, y: 0, w: 4, h: 6 },
      { i: 'statistics', x: 0, y: 6, w: 4, h: 5 },
      { i: 'internship_offers', x: 4, y: 6, w: 4, h: 5 },
    ],
    sm: [
      { i: 'internships', x: 0, y: 0, w: 4, h: 5 },
      { i: 'calender', x: 4, y: 4, w: 4, h: 5 },
      { i: 'statistics', x: 0, y: 6, w: 4, h: 5 },
      { i: 'internship_offers', x: 4, y: 6, w: 4, h: 5 },
    ],
  }

  const elements: IFamulenzGridLayoutElement[] = [
    {
      i: 'internships',
      element: (
        <>
          <p>Keine offenen Bewerbungen</p>
          <br />
          <FamulenzLink to="/organization/internship-offers">
            Zur Stellenverwaltung
          </FamulenzLink>
        </>
      ),
      title: 'Offene Bewerbungen',
    },
    {
      i: 'calender',
      element: (
        <>
          <p>Keine heutigen Ereignisse</p>
          <br />
          <FamulenzLink to="/organization/calendar">Zum Kalender</FamulenzLink>
        </>
      ),
      title: 'Heutige Ereignisse',
    },
    {
      i: 'statistics',
      element: (
        <>
          <p>Hier werden später Statistiken und Metriken angezeigt.</p>
        </>
      ),
      title: 'Statistiken',
    },
    {
      i: 'internship_offers',
      element: (
        <>
          <p>
            Hier werden später Informationen zu den aktiven Ausschreibungen
            angezeigt.
          </p>
          <br />
          <FamulenzLink to="/organization/internship-offers">
            Zum den Ausschreibunen
          </FamulenzLink>
        </>
      ),
      title: 'Aktive Ausschreibungen',
    },
  ]

  return (
    <>
      <FamulenzGridLayout
        layouts={layouts}
        elements={elements}
        breadcrumbEntries={[
          {
            text: 'Dashboard',
            to: '/organization',
            isRoot: true,
            icon: <BsSpeedometer2 />,
          },
        ]}
      />
    </>
  )
}
