import { useEffect, useRef, type RefObject } from 'react'

function useOutsideClick<T extends HTMLElement>(
  callback: () => void,
): RefObject<T> {
  const ref = useRef<T>(null)

  useEffect(() => {
    const handleClick = (event: any): void => {
      if (ref.current != null && !ref.current.contains(event.target)) {
        callback()
      }
    }

    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  return ref
}

export { useOutsideClick }
