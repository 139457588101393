import type {
  IOrganizationInternshipOffersOwnRequest,
  IOrganizationInternshipOffersOwnResponse,
} from './organizationInternshipOffersOwnInterface'
import createApiEndpoint from 'app/api/utils/createApiEndpoint'
import type { RootState } from 'app/store'
import type { IApiState } from 'app/api/models/ApiState'

/* Create selector */
const organizationInternshipOffersOwnEndpointSelector = (
  state: RootState,
): IApiState<IOrganizationInternshipOffersOwnResponse> =>
  state.api.organizationInternshipOffersOwn

/* Create reducer and thunk */
const {
  reducer: organizationInternshipOffersOwnEndpointReducer,
  thunk: organizationInternshipOffersOwnEndpointThunk,
} = createApiEndpoint<
  IOrganizationInternshipOffersOwnRequest,
  IOrganizationInternshipOffersOwnResponse
>({
  endpoint: '/internship-offers/own',
  method: 'GET',
  stateName: 'organizationInternshipOffersOwn',
})

/* Export reducer, selector and thunk action */
export {
  organizationInternshipOffersOwnEndpointSelector,
  organizationInternshipOffersOwnEndpointThunk,
}
export default organizationInternshipOffersOwnEndpointReducer
