import classNames from 'classnames'
import React, { useCallback } from 'react'
import styles from './FamulenzInputBase.module.scss'

/**
 * The base component for all input fields
 * Providing basic functionality like label, error, ...
 */

export interface IFamulenzInputBaseProps {
  // Label for the input field
  label?: string
  // The id of the input field
  id: string
  // Determines if the input field is required
  required?: boolean
  // Additional class name applied to the wrapper div
  className?: string
  // The actual error message
  error?: string
  // The input element
  children: React.JSX.Element
  // Descriptive text for the input field
  description?: any
}

export default function FamulenzInputBase(
  props: IFamulenzInputBaseProps,
): React.JSX.Element {
  /* Callbacks */
  const hasError = useCallback(() => {
    return props.error !== undefined && props.error !== ''
  }, [props.error])

  return (
    <div className={props.className}>
      {props.label !== undefined && (
        <>
          <label className={styles.label} htmlFor={props.id}>
            {props.label}{' '}
            {(props.required ?? false) && (
              <>
                <span className={classNames({ [styles.error]: hasError() })}>
                  *
                </span>
              </>
            )}
          </label>
        </>
      )}

      {props.children}

      {hasError() && (
        <>
          <p className={styles.error}>{props.error}</p>
        </>
      )}

      {props.description !== undefined && !hasError() && (
        <>
          <p id={`${props.id}-description`} className={styles.description}>
            {props.description}
          </p>
        </>
      )}
    </div>
  )
}
