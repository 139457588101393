import { routes } from 'routes'
import { generateFlattenRoutes } from './generate-routes'

export function getTitleFromPath(path: string): string {
  let flattenedRoutes: any[] = []

  routes.forEach(route => {
    const _flattendedRoutes = generateFlattenRoutes(route.routes)
    flattenedRoutes = flattenedRoutes.concat(_flattendedRoutes)
  })

  while (true) {
    for (let i = 0; i < flattenedRoutes.length; i++) {
      if (
        flattenedRoutes[i]?.path !== undefined &&
        flattenedRoutes[i]?.path === path
      ) {
        return flattenedRoutes[i].title
      }
    }

    if (!path.includes('/')) return ''

    path = path.substring(0, path.lastIndexOf('/'))
  }
}
