import React from 'react'
import styles from './FamulenzContinueScrollButton.module.scss'
import classNames from 'classnames'

interface IProps {
  onClick: () => void
}

export default function FamulenzContinueScrollButton(
  props: IProps,
): React.JSX.Element {
  return (
    <div className={styles.container}>
      <button className={styles.button} onClick={props.onClick}>
        <svg
          className={classNames(styles.svg, 'w-[16px] h-[16px]')}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 8"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
          />
        </svg>
      </button>
    </div>
  )
}
