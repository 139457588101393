import React from 'react'
import FamulenzSelect, {
  type IFamulenzSelectProps,
} from '../Select/FamulenzSelect'
import {
  type Control,
  Controller,
  type RegisterOptions,
  type FieldValues,
  type Path,
  type PathValue,
} from 'react-hook-form'

interface IProps<IFormValues extends FieldValues> extends IFamulenzSelectProps {
  name: Path<IFormValues>
  control: Control<IFormValues>
  rules?: RegisterOptions
  defaultValue?: PathValue<IFormValues, Path<IFormValues>>
}

export default function FamulenzSelectControlable<
  IFormValues extends FieldValues,
>(props: IProps<IFormValues>): React.JSX.Element {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      disabled={props.disabled}
      defaultValue={props.defaultValue}
      render={({ field: { onChange, value } }) => (
        <FamulenzSelect
          {...props}
          value={value}
          onChange={e => {
            onChange(e)
            if (props.onChange !== undefined) {
              props.onChange(e)
            }
          }}
        />
      )}
    />
  )
}
