import React from 'react'
import FamulenzInternshipRecommendations from '../FamulenzInternshipRecommendations'
import ColorGradientText from 'components/layout/ColorGradientText/ColorGradientText'
import FamulenzLink from 'components/control/Link/FamulenzLink'
import { toast } from 'react-toastify'
import { type IFamulenzCardWithImageProps } from 'components/layout/CardWithImage/FamulenzCardWithImage'

interface IProps {
  dataAosDelay?: number
}

export default function FamulenzLastChanceInternships(
  props: IProps,
): React.JSX.Element {
  // TODO: load internships from API
  const recommendations: IFamulenzCardWithImageProps[] = [
    {
      title: 'Famulatur Frauenheilkunde und Geburtshilfe',
      text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.',
      to: '/internship/1',
      buttonText: 'Mehr erfahren',
      image: {
        src: 'https://images.pexels.com/photos/3279209/pexels-photo-3279209.jpeg',
        alt: 'Placeholder image',
      },
    },
    {
      title: 'Famulatur Neurologie',
      text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore.',
      to: '/internship/1',
      buttonText: 'Mehr erfahren',
      image: {
        src: 'https://images.pexels.com/photos/7089290/pexels-photo-7089290.jpeg',
        alt: 'Placeholder image',
      },
    },
    {
      title: 'Famulatur Augenheilkunde und Optometrie',
      text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore.',
      to: '/internship/1',
      buttonText: 'Mehr erfahren',
      image: {
        src: 'https://images.pexels.com/photos/5752281/pexels-photo-5752281.jpeg',
        alt: 'Placeholder image',
      },
    },
    {
      title: 'Famulatur Innere Medizin und Kardiologie',
      text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore.',
      to: '/internship/1',
      buttonText: 'Mehr erfahren',
      image: {
        src: 'https://images.pexels.com/photos/6129043/pexels-photo-6129043.jpeg',
        alt: 'Placeholder image',
      },
    },
  ]

  return (
    <>
      <FamulenzInternshipRecommendations
        title={
          <>
            <ColorGradientText>Letzte</ColorGradientText> Chance
          </>
        }
        subtext={
          <>
            Nutze die Gelegenheit und finde{' '}
            <FamulenzLink
              onClick={() => {
                // TODO: implement
                toast.error('Not implemented yet')
              }}
            >
              hier
            </FamulenzLink>{' '}
            weitere Famulaturen, bei denen nur noch wenige Plätze frei sind.
          </>
        }
        recommendations={recommendations}
        dataAosDelay={props.dataAosDelay ?? 0}
        mdCols={2}
        lgCols={2}
        noMarginBottom
      />
    </>
  )
}
