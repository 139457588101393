import type { RootState } from '../../../../store'
import type { IApiState } from '../../../models/ApiState'
import createApiEndpoint from '../../../utils/createApiEndpoint'
import type { IUsersOwnRequest, IUsersOwnResponse } from './usersOwnInterface'

/* Create selector */
const usersOwnEndpointSelector = (
  state: RootState,
): IApiState<IUsersOwnResponse> => state.api.usersOwn

/* Create reducer and thunk */
const { reducer: usersOwnEndpointReducer, thunk: usersOwnEndpointThunk } =
  createApiEndpoint<IUsersOwnRequest, IUsersOwnResponse>({
    endpoint: '/users/own',
    method: 'GET',
    stateName: 'usersOwn',
  })

/* Export reducer, selector and thunk action */
export { usersOwnEndpointSelector, usersOwnEndpointThunk }
export default usersOwnEndpointReducer
