import React from 'react'
import styles from './PasswordResetWithToken.module.scss'
import { useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { LoadingState } from '../../../app/api/models/LoadingState'
import {
  FIELD_REQUIRED_ERROR_TEXT,
  PASSWORD_COMPLEXITY,
} from '../../../app/constants/texts'
import { passwordPattern } from '../../../app/constants/validationPatterns'
import FamulenzButton from '../../../components/control/Button/FamulenzButton'
import FamulenzInput from '../../../components/control/Input/FamulenzInput'
import FamulenzLink from '../../../components/control/Link/FamulenzLink'
import { type IChangePasswordRequest } from '../../../app/api/endpoints/change-password/changePasswordInterface'
import {
  changePasswordEndpointSelector,
  changePasswordEndpointThunk,
} from '../../../app/api/endpoints/change-password'

interface IResetPasswordForm {
  newPassword: string
  newPasswordRepeat: string
}

export default function PasswordResetToken(): React.JSX.Element {
  /* Hooks */
  const dispatch = useAppDispatch()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IResetPasswordForm>()
  const [searchParams] = useSearchParams() // The code from the URL

  /* Selectors */
  const { loading, error: apiError } = useAppSelector(
    changePasswordEndpointSelector,
  )

  /* Handle the submit of the form */
  const onSubmit: SubmitHandler<IResetPasswordForm> = data => {
    const requestData: IChangePasswordRequest = {
      token: searchParams.get('code') ?? '',
      password: data.newPassword,
    }
    dispatch(changePasswordEndpointThunk(requestData))
  }

  /* Check if the two password match */
  const validatePasswordConfirmation = (): string | boolean => {
    if (watch('newPassword') !== watch('newPasswordRepeat')) {
      return 'Die Passwörter stimmen nicht überein.'
    } else {
      return true
    }
  }

  return (
    <>
      <h1 className={styles.header}>Neues Passwort festlegen</h1>

      {loading !== LoadingState.Success && (
        <>
          <p>Sie können nun ein neues Passwort für Ihren Account festlegen.</p>

          <p>{PASSWORD_COMPLEXITY}</p>

          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <FamulenzInput
              label="Passwort"
              id="new-password"
              placeholder="••••••••"
              autoComplete="new-password"
              type="password"
              register={register('newPassword', {
                required: FIELD_REQUIRED_ERROR_TEXT,
                pattern: passwordPattern,
              })}
              error={errors?.newPassword?.message}
              required
            />

            <FamulenzInput
              label="Passwort wiederholen"
              id="confirm-new-password"
              placeholder="••••••••"
              autoComplete="new-password"
              type="password"
              register={register('newPasswordRepeat', {
                required: FIELD_REQUIRED_ERROR_TEXT,
                pattern: passwordPattern,
                validate: validatePasswordConfirmation,
              })}
              error={errors?.newPasswordRepeat?.message}
              required
            />

            {apiError !== null && (
              <p className={styles.error}>
                Das hat leider nicht funktioniert. Möglicherweise ist der Link
                bereits abgelaufen. In diesem Fall können Sie ihr Passwort{' '}
                <FamulenzLink to="/password-reset" error>
                  hier
                </FamulenzLink>{' '}
                erneut zurücksetzen.
              </p>
            )}

            <FamulenzButton
              type="submit"
              loading={loading === LoadingState.Pending}
            >
              Passwort festlegen
            </FamulenzButton>
          </form>
        </>
      )}

      {loading === LoadingState.Success && (
        <>
          <p>
            Ihr Passwort wurde <strong>erfolgreich</strong> geändert. Sie können
            sich nun mit ihrem neuen Passwort einloggen.
          </p>
          <p>
            <FamulenzLink to="/login">Zum Login</FamulenzLink>
          </p>
        </>
      )}
    </>
  )
}
