import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'
import { type IUser } from '../../models/User/User'
import { deleteCookie, setCookie } from '../../utils/cookies'

/* Interface for the state slice */
export interface UserState {
  user: IUser | null
  token: string | null
}

/* Initial state */
const initialState: UserState = {
  user: null,
  token: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginUserAction: (
      state,
      action: PayloadAction<{
        user: IUser
        token: string
        staySignedIn?: boolean
      }>,
    ) => {
      state.user = action.payload.user
      state.token = action.payload.token

      // Store to local store to keep user logged in
      localStorage.setItem('user', JSON.stringify(action.payload.user))
      localStorage.setItem('token', action.payload.token)

      // If the user wants to stay signed in, also store the token as cookie
      if (action.payload.staySignedIn === true) {
        setCookie({
          name: 'token',
          val: action.payload.token,
          expire: new Date(new Date().setMonth(new Date().getMonth() + 1)),
        })
      }
    },
    logoutUserAction: (state, action: PayloadAction<void>) => {
      // Delete the cookie
      deleteCookie({ name: 'token' })

      // Remove from local store
      localStorage.removeItem('user')
      localStorage.removeItem('token')

      state.user = null
      state.token = null
    },
    setUserToken: (state, action: PayloadAction<{ token: string }>) => {
      state.token = action.payload.token
    },
  },
})

/**
 * Export actions
 */
export const { loginUserAction, logoutUserAction, setUserToken } =
  userSlice.actions

/**
 * Export selectors
 */
export const selectIsUserLoggedIn = (state: RootState): boolean => {
  return state.user.token !== null && state.user.user !== null
}

export const selectUser = (state: RootState): IUser | null => {
  return state.user.user
}

export const selectUserToken = (state: RootState): string | null => {
  return state.user.token
}

/**
 * Export default reducer
 */
export default userSlice.reducer
