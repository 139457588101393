import { type IOrganizationalUnitShort } from '../OrganizationalUnit/OrganizationalUnit'
import { InternshipType, type IInternshipOffer } from './InternshipOffer'

export interface IInternshipOfferTransformer extends IInternshipOffer {
  organizationNameWithFirstParent: string
  type: string
  watcherIds: number[]
}

export const getTypeNameFromInternshipOfferType = (
  type: InternshipType,
): string => {
  switch (type) {
    case InternshipType.FAMULATUR:
      return 'Famulatur'
    case InternshipType.KPJ:
      return 'KPJ'
    case InternshipType.HOSPITANT:
      return 'Hospitant'
    case InternshipType.OTHER:
      return 'Anderes'
  }
}

export default function internshipOfferTransformer(
  internshipOffer: IInternshipOffer | null,
): IInternshipOfferTransformer | null {
  if (internshipOffer === null) {
    return null
  }

  /* Returns the name of the organization followed by the name of the first parent */
  const getOrganizationNameWithParent = (): string => {
    const organizationalUnit: IOrganizationalUnitShort =
      internshipOffer.organizational_unit

    if (organizationalUnit === null) {
      return ''
    }

    let name = organizationalUnit.name

    if (
      organizationalUnit?.parents !== undefined &&
      organizationalUnit?.parents.length > 0
    ) {
      // Take the first parent
      name += ` - ${organizationalUnit?.parents[0].name}`
    }

    return name
  }

  /* Returns the ids of the watchers */
  const getWatcherIds = (): number[] => {
    return internshipOffer.watcher.map(watcher => watcher.id)
  }

  return {
    ...internshipOffer,
    type: getTypeNameFromInternshipOfferType(internshipOffer.internship_type),
    watcherIds: getWatcherIds(),
    organizationNameWithFirstParent: getOrganizationNameWithParent(),
  }
}
