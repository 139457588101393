import React, { useCallback } from 'react'
import styles from './FamulenzButton.module.scss'
import classNames from 'classnames'
import FamulenzLoader from '../Loader/FamulenzLoader'

interface IProps {
  onClick?: () => void
  children?: React.ReactNode
  className?: string
  type?: 'button' | 'submit' | 'reset'
  loading?: boolean
  disabled?: boolean
  style?: 'primary' | 'secondary'
  size?: 'sm' | 'md'
}

export default function FamulenzButton(props: IProps): React.JSX.Element {
  const isDisabled = useCallback(() => {
    return (props.disabled ?? false) || (props.loading ?? false)
  }, [props.disabled, props.loading])

  const isStyle = useCallback(
    (style: string): boolean => {
      const currentStyle = props.style ?? 'primary'
      return currentStyle === style
    },
    [props.style],
  )

  return (
    <button
      className={classNames('btn', props.className, styles.button, {
        [styles.primary]: isStyle('primary'),
        [styles.secondary]: isStyle('secondary'),
        [styles.buttonSm]: props.size === 'sm',
        [styles.buttonMd]: props.size === undefined || props.size === 'md',
      })}
      onClick={isDisabled() ? undefined : props.onClick}
      type={props.type ?? 'button'}
      disabled={isDisabled()}
    >
      {(props.loading ?? false) && (
        <div className={styles.loaderContainer}>
          <FamulenzLoader loading={true} color="white" size={8} />
        </div>
      )}

      {!(props.loading ?? false) && <>{props.children}</>}
    </button>
  )
}
