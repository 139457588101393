import { PulseLoader } from 'react-spinners'
import React, { useCallback } from 'react'
import colors from 'styles/colors'

interface IProps {
  loading: boolean
  style?: 'primary' | 'secondary' | 'white'
  [key: string]: any
}

const FamulenzLoader: React.FC<IProps> = ({
  loading,
  style,
  ...otherProps
}) => {
  const getColor = useCallback(() => {
    if (style === 'secondary') {
      return colors.yellow[400]
    } else if (style === 'white') {
      return colors.white
    } else {
      return colors.green[400]
    }
  }, [style])

  return (
    <>
      <PulseLoader
        color={getColor()}
        loading={loading}
        size={20}
        aria-label="Loading Spinner"
        {...otherProps}
      />
    </>
  )
}

export default FamulenzLoader
