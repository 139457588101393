import type {
  IOrganizationInternshipsAllRequest,
  IOrganizationInternshipsAllResponse,
} from './organizationInternshipsAllInterface'
import createApiEndpoint from 'app/api/utils/createApiEndpoint'
import type { RootState } from 'app/store'
import type { IApiState } from 'app/api/models/ApiState'

/* Create selector */
const organizationInternshipsAllEndpointSelector = (
  state: RootState,
): IApiState<IOrganizationInternshipsAllResponse> =>
  state.api.organizationInternshipsAll

/* Create reducer and thunk */
const {
  reducer: organizationInternshipsAllEndpointReducer,
  thunk: organizationInternshipsAllEndpointThunk,
} = createApiEndpoint<
  IOrganizationInternshipsAllRequest,
  IOrganizationInternshipsAllResponse
>({
  endpoint: '/internships',
  method: 'GET',
  stateName: 'organizationInternshipsAll',
})

/* Export reducer, selector and thunk action */
export {
  organizationInternshipsAllEndpointSelector,
  organizationInternshipsAllEndpointThunk,
}
export default organizationInternshipsAllEndpointReducer
