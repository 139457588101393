import {
  type Reducer,
  createAsyncThunk,
  createSlice,
  type AsyncThunk,
} from '@reduxjs/toolkit'
import { LoadingState } from '../models/LoadingState'
import { getFamulenzApiInstance } from '../FamulenzApi'
import { type IApiState } from '../models/ApiState'
import { AxiosError, type Method } from 'axios'
import { mapAxiosErrorToApiError } from '../models/Errors'
import { selectUserToken } from '../../features/User/UserSlice'
import { replacePlaceholders } from './replacePlaceholder'

/* Interface for the createApiEndpoint function */
interface ICreateApiEndpoint {
  // The endpoint to generate the for (e.g. '/login' for the login endpoint)
  endpoint: string
  // The HTTP method for the API call
  method: Method
  // The name under which the state is accessible
  // (e.g. the name that is used in the ApiReducer for example, 'login' for the login endpoint)
  stateName: string
}

/**
 * Function for creating a new API endpoint
 * @param props - The properties for the new API endpoint
 *
 * @template IRequest - The interface for the request of the API call
 * @template IResponse - The interface for the response of the API call
 *
 * @returns The reducer and the thunk for the new API endpoint
 */
const createApiEndpoint = <IRequest extends Record<string, any>, IResponse>(
  props: ICreateApiEndpoint,
): {
  reducer: Reducer<IApiState<IResponse>>
  thunk: AsyncThunk<any, IRequest, any>
} => {
  /* Thunk for fetching data from the API */
  const fetchThunk = createAsyncThunk(
    `api${props.endpoint}/${props.method}/fetch`,
    async (
      data: IRequest,
      { getState, requestId }: { getState: any; requestId: string | undefined },
    ) => {
      // Check if there is alredy a request pending
      const { currentRequestId, loading } = getState().api[props.stateName]
      if (loading !== LoadingState.Pending || requestId !== currentRequestId) {
        // This means that there is an already pending request or the request is outdated
        return
      }

      try {
        // Replace any placeholders in the endpoint with the data
        const _endpoint = replacePlaceholders<IRequest>(props.endpoint, data)

        // Get the user token
        const userToken = selectUserToken(getState())

        // If the method is GET, the params have to be set
        let _params: any
        let _data: IRequest | undefined = data
        if (
          (props.method === 'GET' || props.method === 'get') &&
          data !== undefined
        ) {
          _params = data
          _data = undefined
        }

        // Get the API instance
        const apiInstance = getFamulenzApiInstance({
          token: userToken,
          params: _params,
        })

        // Send request via API and return response
        // Note: Errors will be handled by in the extraReducers of the slice
        const response = await apiInstance({
          method: props.method,
          url: `/api/v1${_endpoint}/`,
          data: _data,
        })

        return response.data
      } catch (error) {
        // Error should always be an AxiosError where the code indicates the HTTP Status Code
        if (error instanceof AxiosError) {
          error.code = (error.response?.status ?? 500).toString()
          throw error
        }

        throw new AxiosError('Internal error', '500')
      }
    },
  )

  /* Initial state for an API endpoint, will be the same for all endpoints */
  const initialState: IApiState<IResponse> = {
    payload: null,
    loading: LoadingState.Idle,
    currentRequestId: undefined,
    error: null,
  }

  /* Create the slice for the new API endpoint */
  const slice = createSlice({
    name: `api${props.endpoint}`,
    initialState,
    reducers: {},
    extraReducers: builder => {
      builder
        /* Handle the case that the thunk is pending and the request has been sent */
        .addCase(fetchThunk.pending, (state, action) => {
          if (
            state.loading === LoadingState.Idle ||
            state.loading === LoadingState.Error ||
            state.loading === LoadingState.Success
          ) {
            state.loading = LoadingState.Pending
            state.currentRequestId = action.meta.requestId
            state.payload = null
            state.error = null
          }
        })

        /* Handle the case that the thunk is fulfilled and the response has been received */
        .addCase(fetchThunk.fulfilled, (state, action) => {
          const { requestId } = action.meta
          if (
            state.loading === LoadingState.Pending &&
            state.currentRequestId === requestId
          ) {
            state.loading = LoadingState.Success
            state.payload = action.payload
            state.currentRequestId = undefined
            state.error = null
          }
        })

        /* Handle the case that the thunk is rejected and an error has been received */
        .addCase(fetchThunk.rejected, (state, action) => {
          const { requestId } = action.meta
          if (
            state.loading === LoadingState.Pending &&
            state.currentRequestId === requestId
          ) {
            state.loading = LoadingState.Error
            state.currentRequestId = undefined
            state.payload = null
            state.error = mapAxiosErrorToApiError(action.error as AxiosError)
          }
        })
    },
  })

  return { reducer: slice.reducer, thunk: fetchThunk }
}

export default createApiEndpoint
