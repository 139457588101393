export enum UserGender {
  MALE,
  FEMALE,
  OTHER,
}

export enum UserType {
  STUDENT = 1,
  ORGANIZATION = 2,
  FAMULENZ_STAFF = 3,
}

export interface IUser {
  id: number
  date_joined: string
  username?: string
  first_name: string
  last_name: string
  email: string
  created_at: string
  email_verified: boolean
  pre_title?: string
  post_title?: string
  gender?: UserGender
  user_type: UserType
}

export interface IUserShort {
  id: number
  username: string
  email: string
  first_name: string
  last_name: string
  user_type: UserType
  post_title?: string
  pre_title?: string
}
