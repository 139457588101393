import React, { useCallback } from 'react'
import styles from './FamulenzLink.module.scss'
import { Link, type To } from 'react-router-dom'
import classNames from 'classnames'

interface IProps {
  children?: React.ReactNode
  className?: string
  to?: To
  href?: string
  target?: string
  error?: boolean
  disabled?: boolean
  secondary?: boolean
  onClick?: () => void
}

export default function FamulenzLink(props: IProps): React.JSX.Element {
  /* Handle the click of the link */
  const handleClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ): void => {
    if (props.disabled === true) {
      e.preventDefault()
    } else if (props.onClick !== undefined) {
      props.onClick()
    }
  }

  /* Get the correct classes */
  const getClassNames = useCallback(() => {
    return {
      [styles.default]:
        !(props.error ?? false) &&
        !(props.disabled ?? false) &&
        !(props.secondary ?? false),
      [styles.disabled]: props.disabled,
      [styles.error]: props.error,
      [styles.secondary]: props.secondary,
      underline: props.error,
    }
  }, [props.error, props.disabled])

  if (props.to !== undefined) {
    return (
      <Link
        className={classNames(
          'link',
          styles.link,
          props.className,
          getClassNames(),
        )}
        onClick={handleClick}
        to={props.to}
        target={props.target}
        rel={props.target === '_blank' ? 'noopener noreferrer' : undefined}
      >
        {props.children}
      </Link>
    )
  } else {
    return (
      <a
        className={classNames(
          'link',
          styles.link,
          props.className,
          getClassNames(),
        )}
        onClick={handleClick}
        href={props.href}
        target={props.target}
        rel={props.target === '_blank' ? 'noopener noreferrer' : undefined}
      >
        {props.children}
      </a>
    )
  }
}
