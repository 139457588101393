/* Constants for the grid layout */
export const GRID_LAYOUT_COLS = { xl: 12, lg: 10, md: 8, sm: 4 }
export const GRID_LAYOUT_BREAKPOINTS = {
  xl: 1280,
  lg: 1024,
  md: 768,
  sm: 640,
}
export const GRID_LAYOUT_ROW_HEIGHT = 30
export const GRID_LAYOUT_DEFAULT_IS_DRAGGABLE = false
export const GRID_LAYOUT_DEFAULT_IS_RESIZEABLE = false
export const GRID_LAYOUT_CONTAINER_PADDING: [number, number] = [0, 0]
export const GRID_LAYOUT_CONTAINER_MARGIN: [number, number] = [16, 16]
