import React from 'react'
import styles from './PasswordReset.module.scss'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import FamulenzInput from '../../components/control/Input/FamulenzInput'
import FamulenzButton from '../../components/control/Button/FamulenzButton'
import { LoadingState } from '../../app/api/models/LoadingState'
import {
  DEFAULT_ERROR_TEXT,
  FIELD_REQUIRED_ERROR_TEXT,
} from '../../app/constants/texts'
import FamulenzLink from '../../components/control/Link/FamulenzLink'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { emailPattern } from '../../app/constants/validationPatterns'
import {
  requestPasswordResetTokenEndpointSelector,
  requestPasswordResetTokenEndpointThunk,
} from '../../app/api/endpoints/change-password/request_reset_token'
import { type IRequestPasswordResetTokenRequest } from '../../app/api/endpoints/change-password/request_reset_token/requestPasswordResetTokenInterface'

interface IPasswordResetForm {
  email: string
}

export default function PasswordReset(): React.JSX.Element {
  /* Hooks */
  const dispatch = useAppDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IPasswordResetForm>()

  /* Selectors */
  const { loading, error: apiError } = useAppSelector(
    requestPasswordResetTokenEndpointSelector,
  )

  /* Handle the submit of the form */
  const onSubmit: SubmitHandler<IPasswordResetForm> = data => {
    const requestData: IRequestPasswordResetTokenRequest = {
      email: data.email,
      verification_url: `${process.env.REACT_APP_BASE_URL ?? ''}/password-reset/token`,
    }
    dispatch(requestPasswordResetTokenEndpointThunk(requestData))
  }

  return (
    <>
      <h1 className={styles.header}>Passwort zurücksetzen</h1>

      {loading !== LoadingState.Success && (
        <>
          <p>
            Bitte geben Sie die Email-Adresse ein, die Sie bei der Registrierung
            verwendet haben. Wir senden Ihnen eine Email mit Anweisungen zum
            Zurücksetzen Ihres Passworts.
          </p>

          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <FamulenzInput
              label="Email"
              id="email"
              placeholder="student@famulenz.at"
              autoComplete="email"
              register={register('email', {
                required: FIELD_REQUIRED_ERROR_TEXT,
                pattern: emailPattern,
              })}
              error={errors?.email?.message}
              required
            />

            {apiError !== null && (
              <p className={styles.error}>{DEFAULT_ERROR_TEXT}</p>
            )}

            <FamulenzButton
              type="submit"
              loading={loading === LoadingState.Pending}
            >
              Passwort zurücksetzen
            </FamulenzButton>
          </form>
        </>
      )}

      {loading === LoadingState.Success && (
        <>
          <p>
            Vielen Dank! Wenn ein Konto mit der von Ihnen angegebenen
            Email-Adresse existiert, haben wir eine Email mit Anweisungen zum
            Zurücksetzen Ihres Passworts gesendet.
          </p>
          <p>
            Bitte überprüfen Sie Ihren Posteingang und folgen Sie den
            Anweisungen in der Email, um Ihr Passwort zurückzusetzen. Wenn Sie
            die Email nicht innerhalb der nächsten Minuten erhalten, überprüfen
            Sie bitte auch Ihren Spam-Ordner. Alternativ können Sie die Seite
            auch neu laden und eine erneute Anfrage senden.
          </p>
          <p>
            <FamulenzLink to="/login">Zurück zum Login</FamulenzLink>
          </p>
        </>
      )}
    </>
  )
}
