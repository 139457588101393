import React, { useCallback } from 'react'
import styles from './FamulenzLogo.module.scss'
import { ReactComponent as FamulenzLogoImage } from '../../../assets/img/famulenz_logo.svg'
import classNames from 'classnames'

interface IFamulenzLogoProps {
  className?: string
  hideText?: boolean
  size: 'lg' | 'md' | 'sm' | 'xs'
  href?: string
}

export default function FamulenzLogo(
  props: IFamulenzLogoProps,
): React.JSX.Element {
  const getSizeClassNamesImage = useCallback((): string => {
    if (props.size === 'lg') {
      return 'w-16 h-16 mr-4'
    } else if (props.size === 'md') {
      return 'w-14 h-14 mr-4'
    } else if (props.size === 'sm') {
      return 'w-10 h-10 mr-3'
    } else if (props.size === 'xs') {
      return 'w-7 h-7 mr-2'
    }

    return ''
  }, [props.size])

  const getSizeClassNamesText = useCallback((): string => {
    if (props.size === 'lg') {
      return 'text-2xl'
    } else if (props.size === 'md') {
      return 'text-xl'
    } else if (props.size === 'sm') {
      return 'text-xl'
    } else if (props.size === 'xs') {
      return 'text-md'
    }

    return ''
  }, [props.size])

  return (
    <div className={classNames(props.className, styles.container)}>
      <FamulenzLogoImage className={getSizeClassNamesImage()} />

      {!(props.hideText ?? false) && (
        <span className={classNames(styles.text, getSizeClassNamesText())}>
          Famulenz
        </span>
      )}
    </div>
  )
}
