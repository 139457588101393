import type { AxiosInstance, CreateAxiosDefaults } from 'axios'
import axios from 'axios'

const FamulenzApiConfig: CreateAxiosDefaults = {
  baseURL: process.env.REACT_APP_API_BASE_URL ?? '',
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 2000,
  responseType: 'json',
}

interface IGetFamulenzApiInstance {
  token?: string | null
  params?: any
}
const getFamulenzApiInstance = (
  props: IGetFamulenzApiInstance,
): AxiosInstance => {
  // Deep copy of the basic config
  const apiConfig = {
    params: props.params ?? undefined,
    ...FamulenzApiConfig,
  }

  // Add additional headers if neccessary
  let additionalHeader = {}
  if (props.token !== undefined && props.token !== null) {
    additionalHeader = {
      ...additionalHeader,
      Authorization: `Token ${props.token}`,
    }
  }

  // Combine the two headers
  const headers = {
    ...apiConfig.headers,
    ...additionalHeader,
  }
  apiConfig.headers = headers

  // Return the axios instance with the config
  return axios.create(apiConfig)
}

export { getFamulenzApiInstance }
