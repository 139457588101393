import FamulenzLink from 'components/control/Link/FamulenzLink'
import FamulenzLogo from 'components/layout/FamulenzLogo/FamulenzLogo'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './StudentHeader.module.scss'
import FamulenzButton from 'components/control/Button/FamulenzButton'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'app/hooks'
import { selectIsUserLoggedIn, selectUser } from 'app/features/User/UserSlice'
import Avatar, { type IDropdownLink } from 'components/layout/Avatar/Avatar'
import userTransformer from 'app/models/User/User.transformer'
import { UserType } from 'app/models/User/User'

interface IProps {}

export default function StudentHeader(props: IProps): React.JSX.Element {
  /* Hooks */
  const navigate = useNavigate()
  const [mobileNavOpen, setMobileNavOpen] = useState<boolean>(false)

  /* Selectors */
  const isUserLoggedIn = useAppSelector(selectIsUserLoggedIn)
  const user = userTransformer(useAppSelector(selectUser))

  /* Refs */
  const trigger = useRef<HTMLButtonElement>(null)
  const mobileNav = useRef<HTMLDivElement>(null)

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }: { target: EventTarget | null }): void => {
      if (mobileNav.current == null || trigger.current == null) return
      if (
        !mobileNavOpen ||
        mobileNav.current.contains(target as Node) ||
        trigger.current.contains(target as Node)
      )
        return
      setMobileNavOpen(false)
    }
    document.addEventListener('click', clickHandler)
    return () => {
      document.removeEventListener('click', clickHandler)
    }
  })

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: { keyCode: number }): void => {
      if (!mobileNavOpen || keyCode !== 27) return
      setMobileNavOpen(false)
    }
    document.addEventListener('keydown', keyHandler)
    return () => {
      document.removeEventListener('keydown', keyHandler)
    }
  })

  // Get the menu links
  const getMenuLinks = useCallback((): React.JSX.Element => {
    // TODO: implement header links
    return (
      <>
        <li>
          <FamulenzLink href="#" className={styles.link} secondary>
            Test
          </FamulenzLink>
        </li>
        <li>
          <FamulenzLink href="#" className={styles.link} secondary>
            Test
          </FamulenzLink>
        </li>
      </>
    )
  }, [])

  const getAvatarLinks = useCallback((): IDropdownLink[] => {
    // TODO: implement avatar links
    if (user !== null && user.type >= UserType.ORGANIZATION) {
      return [
        { to: '#', text: 'Benutzerprofil' },
        { to: '/organization', text: 'Verwaltung' },
        { to: '#', text: 'Einstellungen' },
        { to: '#', text: 'Feedback' },
      ]
    } else {
      return [
        { to: '#', text: 'Benutzerprofil' },
        { to: '#', text: 'Einstellungen' },
        { to: '#', text: 'Feedback' },
      ]
    }
  }, [isUserLoggedIn])

  // Get the user icon or login button depending on state
  const getUserContent = useCallback((): React.JSX.Element => {
    if (isUserLoggedIn) {
      return (
        <Avatar
          middleSection={getAvatarLinks()}
          endSection={[{ to: '/logout', text: 'Abmelden' }]}
        />
      )
    } else {
      return (
        <div className={styles.login}>
          <FamulenzButton
            onClick={() => {
              navigate('/login')
            }}
          >
            Login
          </FamulenzButton>
        </div>
      )
    }
  }, [isUserLoggedIn])

  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <div className={styles.containerInner}>
          <div className={styles.content}>
            {/* Logo */}
            <FamulenzLink to="/">
              <FamulenzLogo size="sm" />
            </FamulenzLink>

            {/* Desktop navigation */}
            <nav className={styles.desktop}>
              {/* Desktop menu links */}
              <ul className={styles.menu}>{getMenuLinks()}</ul>

              <div className={styles.userContainer}>{getUserContent()}</div>
            </nav>

            {/* Mobile Menu */}
            <div className={styles.mobile}>
              <div className={styles.userContainer}>{getUserContent()}</div>

              {/* Hamburger button */}
              <button
                ref={trigger}
                className={`hamburger ${mobileNavOpen && 'active'}`}
                aria-controls="mobile-nav"
                aria-expanded={mobileNavOpen}
                onClick={() => {
                  setMobileNavOpen(!mobileNavOpen)
                }}
              >
                <span className="sr-only">Menu</span>
                <svg
                  className={styles.hamburgerIcon}
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect y="4" width="24" height="2" rx="1" />
                  <rect y="11" width="24" height="2" rx="1" />
                  <rect y="18" width="24" height="2" rx="1" />
                </svg>
              </button>

              {/* Mobile navigation */}
              <nav
                id="mobile-nav"
                ref={mobileNav}
                className={styles.nav}
                style={
                  mobileNavOpen
                    ? { maxHeight: mobileNav.current?.scrollHeight, opacity: 1 }
                    : { maxHeight: 0, opacity: 0.8 }
                }
              >
                <ul className={styles.menu}>{getMenuLinks()}</ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
