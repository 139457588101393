import internshipOfferTransformer from '../InternshipOffer/InternshipOffer.transformer'
import userTransformer from '../User/User.transformer'
import { type IInternship } from './Internship'

export interface IInternshipTransformer extends IInternship {
  student_name: string
  station_name: string
  type: string
  timespan: string
}

export default function internshipTransformer(
  internship: IInternship | null,
): IInternshipTransformer | null {
  if (internship === null) {
    return null
  }

  const getStudentName = (): string => {
    const student = userTransformer(internship.student)
    return student?.name ?? ''
  }

  const getTimespan = (): string => {
    const startDate = new Date(internship.start_date)
    const endDate = new Date(internship.end_date)
    return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
  }

  return {
    ...internship,
    student_name: getStudentName(),
    station_name:
      internshipOfferTransformer(internship.internship_offer)
        ?.organizationNameWithFirstParent ?? '',
    type: internshipOfferTransformer(internship.internship_offer)?.type ?? '',
    timespan: getTimespan(),
  }
}
