import React, { useState } from 'react'
import {
  WidthProvider,
  Responsive,
  type Layouts,
  type Layout,
} from 'react-grid-layout'
import FamulenzGridLayoutElement, {
  type IFamulenzGridLayoutElement,
} from '../FamulenzGridLayoutElement/FamulenzGridLayoutElement'
import {
  GRID_LAYOUT_COLS,
  GRID_LAYOUT_ROW_HEIGHT,
  GRID_LAYOUT_BREAKPOINTS,
  GRID_LAYOUT_DEFAULT_IS_DRAGGABLE,
  GRID_LAYOUT_DEFAULT_IS_RESIZEABLE,
  GRID_LAYOUT_CONTAINER_PADDING,
  GRID_LAYOUT_CONTAINER_MARGIN,
} from './FamulenzGridLayoutConstants'
import classNames from 'classnames'
import FamulenzBreadcrumbs, {
  type IBreacdrumbEntry,
} from 'components/control/Breadcrumbs/FamulenzBreadcrumbs'
import styles from './FamulenzGridLayout.module.scss'

const ResponsiveReactGridLayout = WidthProvider(Responsive)

export interface IFamulenzGridLayoutProps {
  layouts: Layouts
  elements: IFamulenzGridLayoutElement[]
  isDraggable?: boolean
  isResizable?: boolean
  breadcrumbEntries: IBreacdrumbEntry[]
}

export default function FamulenzGridLayout(
  props: IFamulenzGridLayoutProps,
): React.JSX.Element {
  /* States */
  const [layouts, setLayouts] = useState<Layouts>(props.layouts)

  /* Handle a change of the Layout */
  const handleLayoutChange = (
    currentLayout: Layout[],
    allLayouts: Layouts,
  ): void => {
    setLayouts(_ => {
      return allLayouts
    })
  }

  return (
    <div className={styles.container}>
      <FamulenzBreadcrumbs
        entries={props.breadcrumbEntries}
        className={styles.breadcrumbs}
      />

      <div className={styles.gridLayoutContainer}>
        <ResponsiveReactGridLayout
          cols={GRID_LAYOUT_COLS}
          rowHeight={GRID_LAYOUT_ROW_HEIGHT}
          breakpoints={GRID_LAYOUT_BREAKPOINTS}
          layouts={layouts}
          onLayoutChange={handleLayoutChange}
          isDraggable={props.isDraggable ?? GRID_LAYOUT_DEFAULT_IS_DRAGGABLE}
          isResizable={props.isResizable ?? GRID_LAYOUT_DEFAULT_IS_RESIZEABLE}
          containerPadding={GRID_LAYOUT_CONTAINER_PADDING}
          margin={GRID_LAYOUT_CONTAINER_MARGIN}
        >
          {props.elements.map((element: IFamulenzGridLayoutElement) => {
            return (
              <div
                key={element.i}
                className={classNames({
                  '!min-h-full': element.fullHeight === true,
                })}
              >
                <FamulenzGridLayoutElement {...element} />
              </div>
            )
          })}
        </ResponsiveReactGridLayout>
      </div>
    </div>
  )
}
