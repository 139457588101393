import React, { useEffect, useState } from 'react'
import styles from './Register.module.scss'
import { LoadingState } from '../../app/api/models/LoadingState'
import {
  DEFAULT_ERROR_TEXT,
  EMAIL_ALREADY_EXISTS_ERROR_TEXT,
  FIELD_REQUIRED_ERROR_TEXT,
  PASSWORD_COMPLEXITY,
} from '../../app/constants/texts'
import {
  emailPattern,
  passwordPattern,
} from '../../app/constants/validationPatterns'
import FamulenzButton from '../../components/control/Button/FamulenzButton'
import FamulenzCheckbox from '../../components/control/Checkbox/FamulenzCheckbox'
import FamulenzInput from '../../components/control/Input/FamulenzInput'
import FamulenzLink from '../../components/control/Link/FamulenzLink'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  registerEndpointSelector,
  registerEndpointThunk,
} from '../../app/api/endpoints/register'
import { type IRegisterRequest } from '../../app/api/endpoints/register/registerInterface'

interface IRegisterForm {
  email: string
  password: string
  passwordRepeat: string
  acceptGdpr: boolean
}

export default function Register(): React.JSX.Element {
  /* States */
  const [errorString, setErrorString] = useState<string | null>(null)

  /* Hooks */
  const dispatch = useAppDispatch()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IRegisterForm>()

  /* Selectors */
  const { loading, error: apiError } = useAppSelector(registerEndpointSelector)

  /* Handle the submit of the form */
  const onSubmit: SubmitHandler<IRegisterForm> = data => {
    const requestData: IRegisterRequest = {
      email: data.email,
      password: data.password,
      verification_url: `${process.env.REACT_APP_BASE_URL ?? ''}/register/verify`,
    }

    dispatch(registerEndpointThunk(requestData))
  }

  /* Check if the two password match */
  const validatePasswordConfirmation = (): string | boolean => {
    if (watch('password') !== watch('passwordRepeat')) {
      return 'Die Passwörter stimmen nicht überein.'
    } else {
      return true
    }
  }

  /* Set the error string based on the API error */
  useEffect(() => {
    if (apiError !== null) {
      if (apiError.status === 400) {
        setErrorString(EMAIL_ALREADY_EXISTS_ERROR_TEXT)
      } else {
        setErrorString(DEFAULT_ERROR_TEXT)
      }
    } else {
      setErrorString(null)
    }
  }, [apiError])

  return (
    <>
      <h1 className={styles.header}>Studierenden Registrierung</h1>

      {loading !== LoadingState.Success && (
        <>
          <p>{PASSWORD_COMPLEXITY}</p>

          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <FamulenzInput
              label="Email"
              id="email"
              placeholder="student@famulenz.at"
              autoComplete="email"
              register={register('email', {
                required: FIELD_REQUIRED_ERROR_TEXT,
                pattern: emailPattern,
              })}
              error={errors?.email?.message}
              required
            />

            <FamulenzInput
              label="Passwort"
              id="new-password"
              placeholder="••••••••"
              autoComplete="new-password"
              type="password"
              register={register('password', {
                required: FIELD_REQUIRED_ERROR_TEXT,
                pattern: passwordPattern,
              })}
              error={errors?.password?.message}
              required
            />

            <FamulenzInput
              label="Passwort wiederholen"
              id="confirm-new-password"
              placeholder="••••••••"
              autoComplete="new-password"
              type="password"
              register={register('passwordRepeat', {
                required: FIELD_REQUIRED_ERROR_TEXT,
                pattern: passwordPattern,
                validate: validatePasswordConfirmation,
              })}
              error={errors?.passwordRepeat?.message}
              required
            />

            <FamulenzCheckbox
              id="accept-gdpr"
              label={
                <>
                  Ich akzeptiere die{' '}
                  <FamulenzLink
                    href="https://www.famulenz.at/datenschutz"
                    target="_blank"
                  >
                    Datenschutzerklärung
                  </FamulenzLink>
                  .
                </>
              }
              register={register('acceptGdpr', {
                required: FIELD_REQUIRED_ERROR_TEXT,
              })}
              error={errors?.acceptGdpr?.message}
              required
            />

            {errorString !== null && (
              <p className={styles.error}>{errorString}</p>
            )}

            <FamulenzButton
              type="submit"
              loading={loading === LoadingState.Pending}
            >
              Registrieren
            </FamulenzButton>

            <p className={styles.bottomText}>
              Die Registrierung steht ausschließlich Studierenden zur Verfügung.
              Ausbildungsstätten, die sich registrieren möchten, können uns
              jederzeit unverbindlich unter{' '}
              <FamulenzLink href="mailto:office@famulenz.at">
                office@famulenz.at
              </FamulenzLink>{' '}
              kontaktieren.
            </p>
          </form>
        </>
      )}

      {loading === LoadingState.Success && (
        <>
          <p>
            Vielen Dank! Ihr Konto wurde erfolgreich erstellt. Wir haben Ihnen
            eine Email zur Bestätigung Ihres Kontos gesendet. Sobald Sie Ihr
            Konto bestätigt haben, können Sie sich einloggen.
          </p>
          <p>
            <FamulenzLink to="/login">Zum Login</FamulenzLink>
          </p>
        </>
      )}
    </>
  )
}
