import React, { useEffect } from 'react'
import styles from './NeedsVerification.module.scss'
import FamulenzButton from '../../../components/control/Button/FamulenzButton'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  verificationCodeWithEmailEndpointSelector,
  verificationCodeWithEmailEndpointThunk,
} from '../../../app/api/endpoints/users/verification-code-with-email'
import { LoadingState } from '../../../app/api/models/LoadingState'
import { DEFAULT_ERROR_TEXT } from '../../../app/constants/texts'
import FamulenzLink from '../../../components/control/Link/FamulenzLink'
import { useLocation, useNavigate } from 'react-router-dom'
import { type IVerificationCodeWithEmailRequest } from '../../../app/api/endpoints/users/verification-code-with-email/verificationCodeWithEmailInterface'

export default function NeedsVerification(): React.JSX.Element {
  /* Hooks */
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { loading, error } = useAppSelector(
    verificationCodeWithEmailEndpointSelector,
  )

  /* Check if the email is set in history state */
  useEffect(() => {
    if (location.state?.email === undefined || location.state?.email === null) {
      navigate('/login')
    }
  }, [location])

  /* Callback function for button click */
  const handleButtonClick = (): void => {
    const data: IVerificationCodeWithEmailRequest = {
      email: location.state.email ?? '',
      verification_url: `${process.env.REACT_APP_BASE_URL ?? ''}/register/verify`,
    }
    dispatch(verificationCodeWithEmailEndpointThunk(data))
  }

  return (
    <>
      <h1 className={styles.header}>Account Verifizierung ausständig</h1>

      {loading !== LoadingState.Success && (
        <>
          <p>
            Es sieht so aus, als ob Ihr Account noch nicht bestätigt wurde.
            Bitte bestätigen Sie Ihren Account, indem Sie den Anweisungen in der
            E-Mail folgen, die wir Ihnen gesendet haben. Falls Sie noch keine
            E-Mail von uns erhalten haben, können Sie durch einen Klick auf den
            untenstehenden Button eine neue Email beantragen.
          </p>

          {error !== null && (
            <p className={styles.error}>{DEFAULT_ERROR_TEXT}</p>
          )}

          <FamulenzButton
            onClick={handleButtonClick}
            loading={loading === LoadingState.Pending}
          >
            Email erneut senden
          </FamulenzButton>
        </>
      )}

      {loading === LoadingState.Success && (
        <>
          <p>
            Wir haben Ihnen eine Email zur Bestätigung Ihres Kontos gesendet.
            Sobald Sie Ihr Konto bestätigt haben, können Sie sich einloggen.
          </p>
          <p>
            <FamulenzLink to="/login">Zum Login</FamulenzLink>
          </p>
        </>
      )}
    </>
  )
}
