import React, { useCallback, useState } from 'react'
import styles from './FamulenzMultiStepProcess.module.scss'
import FamulenzButton from '../Button/FamulenzButton'
import {
  type UseFormTrigger,
  type FieldValues,
  type UseFormHandleSubmit,
  type Path,
  type UseFormGetValues,
} from 'react-hook-form'

export enum FamulenzMultiStepProcessDirection {
  BACK = 'back',
  NEXT = 'next',
}

export interface IFamulenzMultiStepProcessStep<IFormValues> {
  // A unique key for the step
  key: string
  // The content of the current step
  content: React.JSX.Element
  // The fields of the current step that should trigger the validation
  triggerFields: Array<Path<IFormValues>>
  // The description of the current step that is displayed on the top
  description?: string
}

interface IProps<IFormValues extends FieldValues> {
  // The different steps with the content and the input elements
  steps: Array<IFamulenzMultiStepProcessStep<IFormValues>>
  // Callback function from useForm to trigger the validation of the form
  trigger: UseFormTrigger<IFormValues>
  // Callback function from useForm to retrieve the current values of the form
  getValues: UseFormGetValues<IFormValues>
  // Callback function from useForm to handle the submit of the form
  handleSubmit: UseFormHandleSubmit<IFormValues, IFormValues>
  // Is called when a step changes
  onStepChange?: ({
    step,
    data,
    type,
  }: {
    step: number
    data: IFormValues
    type: FamulenzMultiStepProcessDirection
  }) => void
  // The callback function that is called when the whole form is submitted in the last step
  onSubmit: (data: IFormValues) => void
  // Determines whether the form is loading (this will make the buttons disabled)
  loading?: boolean
}

export default function FamulenzMultiStepProcess<
  IFormValues extends FieldValues,
>(props: IProps<IFormValues>): React.JSX.Element {
  /* States */
  const [currentStep, setCurrentStep] = useState<number>(0)

  /* Callbacks */
  const getCurrentStep =
    useCallback((): IFamulenzMultiStepProcessStep<IFormValues> => {
      if (currentStep < 0) return props.steps[0]
      if (currentStep >= props.steps.length)
        return props.steps[props.steps.length - 1]
      return props.steps[currentStep]
    }, [currentStep, props.steps])

  // TODO: implement types
  const getDataOfCurrentStep = (): IFormValues => {
    const data: any = {}
    getCurrentStep().triggerFields.forEach(field => {
      data[field] = props.getValues(field)
    })
    return data
  }

  /* Handle the click on the next button */
  const onNextClick = async (): Promise<void> => {
    // Validate the current step
    const valid = await props.trigger(getCurrentStep().triggerFields, {
      shouldFocus: true,
    })

    // On success, continue with the next step
    if (valid) {
      // Call the callback
      if (props.onStepChange !== undefined) {
        props.onStepChange({
          step: currentStep,
          data: getDataOfCurrentStep(),
          type: FamulenzMultiStepProcessDirection.NEXT,
        })
      }

      // Move to the next step
      setCurrentStep(_step => {
        if (_step < props.steps.length - 1) return _step + 1
        else return _step
      })
    }
  }

  /* Handle the back click */
  const onBackClick = (): void => {
    // Call the callback
    if (props.onStepChange !== undefined) {
      props.onStepChange({
        step: currentStep,
        data: getDataOfCurrentStep(),
        type: FamulenzMultiStepProcessDirection.NEXT,
      })
    }

    setCurrentStep(_step => {
      if (_step > 0) return _step - 1
      else return _step
    })
  }

  return (
    <form
      className={styles.container}
      onSubmit={props.handleSubmit(props.onSubmit)}
    >
      {getCurrentStep().description !== undefined && (
        <p className={styles.description}>{getCurrentStep().description}</p>
      )}

      <div className={styles.content} key={getCurrentStep().key}>
        {getCurrentStep().content}
      </div>

      <div className={styles.footer}>
        {currentStep > 0 && (
          <>
            <FamulenzButton
              type="button"
              style="secondary"
              onClick={onBackClick}
              loading={props.loading}
            >
              Zurück
            </FamulenzButton>
          </>
        )}

        {currentStep < props.steps.length - 1 && (
          <>
            <FamulenzButton
              type="button"
              onClick={onNextClick}
              loading={props.loading}
            >
              Weiter
            </FamulenzButton>
          </>
        )}

        {currentStep === props.steps.length - 1 && (
          <>
            <FamulenzButton type="submit" loading={props.loading}>
              Speichern
            </FamulenzButton>
          </>
        )}
      </div>
    </form>
  )
}
