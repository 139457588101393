import React, { useCallback } from 'react'
import styles from './LoaderWrapper.module.scss'
import FamulenzLoader from '../Loader/FamulenzLoader'
import { type IApiError } from 'app/api/models/Errors'
import { DEFAULT_ERROR_TEXT } from 'app/constants/texts'

interface IProps {
  loading: boolean
  error?: IApiError | null
  children: any
}

export default function LoaderWrapper(props: IProps): React.JSX.Element {
  const getComponent = useCallback(() => {
    if (props.loading) {
      return (
        <div className={styles.loaderWrapper}>
          <FamulenzLoader loading={true} />
        </div>
      )
    } else if (props.error !== undefined && props.error !== null) {
      return (
        <div className={styles.errorWrapper}>
          <p>{DEFAULT_ERROR_TEXT}</p>
        </div>
      )
    } else {
      return props.children
    }
  }, [props.loading, props.children])

  return getComponent()
}
