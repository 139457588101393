import React, { useState } from 'react'
import styles from './Avatar.module.scss'
import { useAppSelector } from '../../../app/hooks'
import { selectUser } from '../../../app/features/User/UserSlice'
import userTransformer from '../../../app/models/User/User.transformer'
import classNames from 'classnames'
import FamulenzLink from '../../control/Link/FamulenzLink'
import { useOutsideClick } from '../../utils/outsideClick'

export interface IDropdownLink {
  to: string
  text: string
}

interface IProps {
  middleSection?: IDropdownLink[]
  endSection?: IDropdownLink[]
}

export default function Avatar(props: IProps): React.JSX.Element {
  /* Selectors */
  const user = userTransformer(useAppSelector(selectUser))

  /* States */
  const [isOpen, setIsOpen] = useState(false)

  /* Handle a click outside the div */
  const handleOutsideClick = (): void => {
    setIsOpen(false)
  }
  const ref = useOutsideClick<HTMLDivElement>(handleOutsideClick)

  /* Handle the click on the dropdown */
  const handleDropdownClick = (): void => {
    setIsOpen(_isOpen => !_isOpen)
  }

  return (
    <div ref={ref}>
      <button
        onClick={handleDropdownClick}
        className={styles.button}
        type="button"
      >
        <span className="sr-only">Benutzermenü öffnen</span>
        <div className={styles.initials}>
          <span className={styles.text}>{user?.initials}</span>
        </div>

        <span className={styles.name}>{user?.name}</span>

        <svg
          className={styles.svg}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>

      {/* <!-- Dropdown menu --> */}
      <div
        className={classNames(styles.dropdownMenu, {
          hidden: !isOpen,
          block: isOpen,
        })}
      >
        <div className={styles.emailContainer}>
          <div className={styles.emailContainerText}>{user?.email}</div>
        </div>

        {props.middleSection != null && props.middleSection.length > 0 && (
          <>
            <ul className={styles.middleSection}>
              <li>
                {props.middleSection.map((link, index) => (
                  <FamulenzLink
                    key={index}
                    to={link.to}
                    className={styles.linkElement}
                  >
                    {link.text}
                  </FamulenzLink>
                ))}
              </li>
            </ul>
          </>
        )}

        {props.endSection != null && props.endSection.length > 0 && (
          <div className={styles.endSection}>
            {props.endSection.map((link, index) => (
              <FamulenzLink
                key={index}
                to={link.to}
                className={styles.linkElement}
              >
                {link.text}
              </FamulenzLink>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
