import React from 'react'
import styles from './ColorGradientText.module.scss'
import classNames from 'classnames'

interface IProps {
  children: any
}

export default function ColorGradientText(props: IProps): React.JSX.Element {
  return (
    <span
      className={classNames(
        styles.colorGradient,
        'from-green-500 from-30% via-green-400 via-40% to-yellow-400',
      )}
    >
      {props.children}
    </span>
  )
}
