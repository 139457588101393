function replacePlaceholders<T extends Record<string, any>>(
  template: string,
  data: T,
): string {
  return template.replace(/{([^}]+)}/g, (_, match) => {
    if (match in data) {
      return data[match]
    } else {
      return ''
    }
  })
}

export { replacePlaceholders }
