import React from 'react'
import { Outlet } from 'react-router-dom'
import styles from './ContainerLayout.module.scss'
import FamulenzLogo from '../../components/layout/FamulenzLogo/FamulenzLogo'
import FamulenzLink from '../../components/control/Link/FamulenzLink'

export default function ContainerLayout(): React.JSX.Element {
  return (
    <>
      <div className={styles.container}>
        {/* Logo */}
        <FamulenzLink
          to="https://www.famulenz.at"
          target="_blank"
          className={styles.logo}
        >
          <FamulenzLogo size="lg" />
        </FamulenzLink>

        {/* Content */}
        <div className={styles.containerContent}>
          <div className={styles.containerContentInner}>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}
