import { type RootState } from '../../../store'
import { type IApiState } from '../../models/ApiState'
import createApiEndpoint from '../../utils/createApiEndpoint'
import {
  type IRegisterRequest,
  type IRegisterResponse,
} from './registerInterface'

/* Create selector */
const registerEndpointSelector = (
  state: RootState,
): IApiState<IRegisterResponse> => state.api.register

/* Create reducer and thunk */
const { reducer: registerEndpointReducer, thunk: registerEndpointThunk } =
  createApiEndpoint<IRegisterRequest, IRegisterResponse>({
    endpoint: '/register',
    method: 'POST',
    stateName: 'register',
  })

/* Export reducer, selector and thunk action */
export { registerEndpointSelector, registerEndpointThunk }
export default registerEndpointReducer
