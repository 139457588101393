export interface ISetCookie {
  name: string
  val: string
  expire?: Date
}
export function setCookie(props: ISetCookie): void {
  let expireDate: Date | null = null
  if (props.expire !== undefined) {
    // Set the provided expire date
    expireDate = props.expire
  } else {
    // Default expiry date to 14 days
    expireDate = new Date()
    expireDate.setTime(expireDate.getTime() + 14 * 24 * 60 * 60 * 1000)
  }

  // Set it
  document.cookie =
    props.name +
    '=' +
    props.val +
    '; expires=' +
    expireDate.toUTCString() +
    '; path=/'
}

export interface IGetCookie {
  name: string
}
export function getCookie(props: IGetCookie): string | undefined {
  const value = '; ' + document.cookie
  const parts = value.split('; ' + props.name + '=')

  if (parts.length === 2) {
    return parts.pop()?.split(';').shift()
  }
}

export interface IDeleteCookie {
  name: string
}
export function deleteCookie(props: IDeleteCookie): void {
  const date = new Date()

  // Set expire date to -1 day which makes it invalid
  date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000)

  // Set it
  document.cookie = props.name + '=; expires=' + date.toUTCString() + '; path=/'
}
