import { type RootState } from '../../../../store'
import { type IApiState } from '../../../models/ApiState'
import createApiEndpoint from '../../../utils/createApiEndpoint'
import {
  type IVerificationCodeWithEmailRequest,
  type IVerificationCodeWithEmailResponse,
} from './verificationCodeWithEmailInterface'

/* Create selector */
const verificationCodeWithEmailEndpointSelector = (
  state: RootState,
): IApiState<IVerificationCodeWithEmailResponse> =>
  state.api.verificationCodeWithEmail

/* Create reducer and thunk */
const {
  reducer: verificationCodeWithEmailEndpointReducer,
  thunk: verificationCodeWithEmailEndpointThunk,
} = createApiEndpoint<
  IVerificationCodeWithEmailRequest,
  IVerificationCodeWithEmailResponse
>({
  endpoint: '/users/verification-code-with-email',
  method: 'GET',
  stateName: 'verificationCodeWithEmail',
})

/* Export reducer, selector and thunk action */
export {
  verificationCodeWithEmailEndpointSelector,
  verificationCodeWithEmailEndpointThunk,
}
export default verificationCodeWithEmailEndpointReducer
