import React from 'react'
import { Outlet } from 'react-router-dom'
import AdministrationHeader from '../../components/layout/AdministrationHeader/AdministrationHeader'
import styles from './AdministrationLayout.module.scss'
import AdministrationSideNav from '../../components/layout/AdministrationSideNav/AdministrationSideNav'
import {
  BsCalendar2Week,
  BsGear,
  BsListUl,
  BsMegaphone,
  BsSpeedometer2,
} from 'react-icons/bs'

export default function AdministrationLayout(): React.JSX.Element {
  return (
    <div className={styles.container}>
      <AdministrationHeader />

      <div className={styles.containerInner}>
        <AdministrationSideNav
          entries={[
            {
              text: 'Dashboard',
              icon: <BsSpeedometer2 />,
              to: '/organization',
            },
            {
              text: 'Kalender',
              icon: <BsCalendar2Week />,
              to: '/organization/calendar',
            },
            {
              text: 'Stellenverwaltung',
              icon: <BsListUl />,
              to: '/organization/internships',
            },
            {
              text: 'Ausschreibungen',
              icon: <BsMegaphone />,
              to: '/organization/internship-offers',
            },
            {
              text: 'Verwaltung',
              icon: <BsGear />,
              to: '/organization/administration',
            },
          ]}
          footer={`© Famulenz ${new Date().getFullYear()}`}
        />

        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
    </div>
  )
}
