// TODO: remove
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DataGrid,
  type GridRowId,
  type GridColDef,
  type GridRowsProp,
  GridActionsCellItem,
  type GridPaginationModel,
  type GridSortModel,
} from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import {
  BsEye,
  BsFillCheckCircleFill,
  BsFillXCircleFill,
  BsPencilSquare,
  BsSearch,
} from 'react-icons/bs'
import styles from './InternshipOffersComponent.module.scss'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  organizationInternshipOffersOwnEndpointSelector,
  organizationInternshipOffersOwnEndpointThunk,
} from 'app/api/endpoints/organization/internship-offers/own'
import { type IOrganizationInternshipOffersOwnRequest } from 'app/api/endpoints/organization/internship-offers/own/organizationInternshipOffersOwnInterface'
import LoaderWrapper from 'components/control/LoaderWrapper/LoaderWrapper'
import { LoadingState } from 'app/api/models/LoadingState'
import internshipOfferTransformer, {
  IInternshipOfferTransformer,
} from 'app/models/InternshipOffer/InternshipOffer.transformer'
import { SortOrder } from 'app/models/Pagination/Pagination'
import FamulenzInput from 'components/control/Input/FamulenzInput'
import classNames from 'classnames'
import { DEFAULT_ERROR_TEXT } from 'app/constants/texts'
import FamulenzButton from 'components/control/Button/FamulenzButton'
import { toast } from 'react-toastify'

// TODO: make own component out of this and combine with InternshipsTable
export default function InternshipOffersComponent(): React.JSX.Element {
  /* Hooks */
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { payload, loading, error } = useAppSelector(
    organizationInternshipOffersOwnEndpointSelector,
  )

  /* States */
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  })
  const [sortModel, setSortModel] = useState<GridSortModel>([])
  const [numberResults, setNumberResults] = useState<number>(0)
  const [searchValue, setSearchValue] = useState<string>('')
  // Need a debounced model here, since otherwise a new request would be sent on every key stroke
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('')

  /* Debouncing the search value and introducing artificial delay */
  useEffect(() => {
    const debounceTimeout = window.setTimeout(() => {
      setDebouncedSearchValue(searchValue)
    }, 500)

    // Important to clear the timeout on dismount or before another effect
    return () => {
      clearTimeout(debounceTimeout)
    }
  }, [searchValue, setDebouncedSearchValue])

  /* Load API data */
  useEffect(() => {
    const data: IOrganizationInternshipOffersOwnRequest = {
      page_size: paginationModel.pageSize,
      // Pagination Model is zero based and API pagination is one based
      page: paginationModel.page + 1,
      search: debouncedSearchValue,
    }

    // Get the sort order if defined
    if (sortModel.length > 0) {
      if (sortModel[0].field === 'type') {
        if (sortModel[0].sort === SortOrder.ASC) {
          data.ordering = 'internship_type'
        } else {
          data.ordering = '-internship_type'
        }
      } else if (sortModel[0].field === 'organizationNameWithFirstParent') {
        if (sortModel[0].sort === SortOrder.ASC) {
          data.ordering = 'organizational_unit__name'
        } else {
          data.ordering = '-organizational_unit__name'
        }
      }
    }

    dispatch(organizationInternshipOffersOwnEndpointThunk(data))
  }, [paginationModel, sortModel, debouncedSearchValue])

  /* Update the number of results when the loading of a new API request is finished */
  useEffect(() => {
    if (loading === LoadingState.Success) {
      setNumberResults(payload?.count ?? 0)
    }
  }, [loading, payload])

  /* Callbacks for CRUD operations */
  const handleEditClick = (id: GridRowId): void => {
    navigate(`/organization/internship-offers/${id}`)
  }
  const handleViewClick = (id: GridRowId): void => {
    // TODO: implement view click
    toast.error('View not implemented yet...')
  }

  /* The rows for the table */
  const rows = payload?.results.map(internshipOfferTransformer) ?? []

  /* Definition for columns */
  const columns: GridColDef[] = [
    {
      field: 'organizationNameWithFirstParent',
      headerName: 'Station',
      description: 'Die Station, für die die Ausschreibung gilt',
      flex: 1,
      minWidth: 400,
      sortable: true,
    },
    {
      field: 'type',
      headerName: 'Typ',
      description: 'Der Typ der Ausschreibung',
      sortable: true,
      minWidth: 200,
    },
    {
      field: 'active',
      headerName: 'Öffentlich',
      description: 'Zeigt an, ob die Ausschreibung öffentlich sichtbar ist',
      sortable: false,
      renderCell: ({ value }) => {
        if (value === true) {
          return <BsFillCheckCircleFill size={16} className={styles.online} />
        } else {
          return <BsFillXCircleFill size={16} className={styles.offline} />
        }
      },
    },
    {
      field: 'actions',
      headerName: 'Aktionen',
      type: 'actions',
      sortable: false,
      getActions: ({ id }: { id: GridRowId }) => {
        return [
          <GridActionsCellItem
            key="view"
            icon={<BsEye size={16} />}
            label="Vorschau"
            onClick={() => {
              handleViewClick(id)
            }}
          />,
          <GridActionsCellItem
            key="edit"
            icon={<BsPencilSquare size={16} />}
            label="Bearbeiten"
            onClick={() => {
              handleEditClick(id)
            }}
          />,
        ]
      },
    },
  ]

  return (
    <div className={styles.container}>
      {/* TODO: move the logic to an own paginated component? */}

      <div className={styles.actions}>
        <FamulenzInput
          id="search"
          placeholder="Nach Ausschreibung suchen..."
          className={styles.search}
          defaultValue={searchValue}
          onValueChange={val => {
            setSearchValue(val)
          }}
          icon={BsSearch}
          clearable
        />

        {/* TODO: handle create button click */}
        <FamulenzButton
          className={styles.createButton}
          onClick={() => {
            navigate('/organization/internship-offers/create')
          }}
        >
          Neue Ausschreibung
        </FamulenzButton>
      </div>

      {/* Show error message in case of error */}
      {error !== null && (
        <>
          <p className={styles.error}>{DEFAULT_ERROR_TEXT}</p>
        </>
      )}

      <DataGrid
        className={classNames('rows-clickable', styles.table)}
        rows={rows}
        columns={columns}
        editMode="cell"
        loading={loading === LoadingState.Pending}
        disableRowSelectionOnClick
        onRowClick={event => {
          handleEditClick(event.id)
        }}
        pageSizeOptions={[5, 10, 25, 50]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        rowCount={numberResults}
        paginationMode="server"
        disableColumnFilter={true}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={setSortModel}
      />
    </div>
  )
}
