import { type ValidationRule } from 'react-hook-form'
import { EMAIL_REQUIRED_ERROR_TEXT, PASSWORD_TOO_SIMPLE } from './texts'

const emailPattern: ValidationRule<RegExp> = {
  value: /\S+@\S+\.\S+/,
  message: EMAIL_REQUIRED_ERROR_TEXT,
}

const passwordPattern: ValidationRule<RegExp> = {
  value: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/,
  message: PASSWORD_TOO_SIMPLE,
}

export { emailPattern, passwordPattern }
