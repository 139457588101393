import React from 'react'
import styles from './FamulenzBreadcrumbs.module.scss'
import classNames from 'classnames'
import FamulenzLink from '../Link/FamulenzLink'

interface IBreadcrumbRootEntry {
  text: string
  to: string
  isRoot: true
  icon: React.JSX.Element
}

interface IBreadcrumbChildEntry {
  text: string
  to: string
}

interface IBreadcrumbDisabledEntry {
  text: string
}

export type IBreacdrumbEntry =
  | IBreadcrumbRootEntry
  | IBreadcrumbChildEntry
  | IBreadcrumbDisabledEntry

interface IProps {
  className?: string
  entries: IBreacdrumbEntry[]
}

export default function FamulenzBreadcrumbs(props: IProps): React.JSX.Element {
  const getChevron = (): React.JSX.Element => {
    return (
      <svg
        className={styles.chevron}
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 6 10"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="m1 9 4-4-4-4"
        />
      </svg>
    )
  }

  const isLastEntry = (index: number): boolean => {
    return index === props.entries.length - 1
  }

  return (
    <nav
      className={classNames(props.className, styles.container)}
      aria-label="Breadcrumb"
    >
      <ol className={styles.ol}>
        {props.entries.map((entry, index) => {
          return (
            <li key={index} className={styles.entry}>
              <FamulenzLink
                className={styles.link}
                to={'to' in entry ? entry.to : undefined}
                disabled={!('to' in entry) || isLastEntry(index)}
              >
                {'icon' in entry && entry.icon}
                {entry.text}
              </FamulenzLink>

              {!isLastEntry(index) && (
                <div key={`chevron-${index}`} className={styles.entry}>
                  {getChevron()}
                </div>
              )}
            </li>
          )
        })}
      </ol>
    </nav>
  )
}
