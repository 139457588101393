export enum LoadingState {
  // This means that the request has not been sent yet
  Idle,
  // This means that the request has been sent and it is pending
  Pending,
  // This means that the request has been fulfilled and the response is in the payload
  Success,
  // This means that the request has been rejected and the error is in the error field
  Error,
}
