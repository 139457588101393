// Routes implemented according to following tutorial
// https://najm-eddine-zaga.medium.com/react-render-multiple-layouts-with-react-router-dom-v6-7a42bd984acf
// All the routes can be defined here with different layouts and properties. Then the routes will automatically be flattened and passe don to the router with the correct layout.
// This approach also allows to have nested routes

// Layouts
import ContainerLayout from '../layouts/container/ContainerLayout'
import AdministrationLayout from '../layouts/administration/AdministrationLayout'

// Pages
import Login from '../pages/login/Login'
import Dashboard from '../pages/organization/dashboard/Dashboard'

import type React from 'react'
import { renderRoutes } from './generate-routes'
import Logout from '../pages/logout/Logout'
import PasswordReset from '../pages/password-reset/PasswordReset'
import PasswordResetWithToken from '../pages/password-reset/token/PasswordResetWithToken'
import Register from '../pages/register/Register'
import Calendar from '../pages/organization/calendar/Calendar'
import NeedsVerification from '../pages/register/needs-verification/NeedsVerification'
import Verify from '../pages/register/verify/Verify'
import StudentLayout from 'layouts/student/StudentLayout'
import Index from 'pages/student/index/Index'
import Internships from 'pages/organization/internships/Internships'
import InternshipOffers from 'pages/organization/internship-offers/InternshipOffers'
import InternshipOfferEdit from 'pages/organization/internship-offers/InternshipOfferEdit'
import InternshipOfferCreate from 'pages/organization/internship-offers/InternshipOfferCreate'
import Administration from 'pages/organization/administration/Administration'
import StudentInternshipPage from 'pages/student/internship/StudentInternshipPage'

export interface ILayoutRoute {
  layout: React.ComponentType
  routes: IRoute[]
}

export enum AuthLevel {
  PUBLIC = 1,
  STUDENT = 2,
  ORGANIZATION = 3,
  FAMULENZ_STAFF = 4,
}

export interface IRouteWithPath {
  name: string
  title: string
  component: React.ComponentType
  path: string
  // The auth level required for the page to view, default is public
  auth: AuthLevel
  routes?: IRoute[]
}

export interface IRouteWithoutPath {
  name: string
  title: string
  routes?: IRoute[]
}

export type IRoute = IRouteWithPath | IRouteWithoutPath

export const routes: ILayoutRoute[] = [
  /* Student routes */
  {
    layout: StudentLayout,
    routes: [
      {
        name: 'index',
        title: 'Home',
        component: Index,
        path: '/',
        auth: AuthLevel.PUBLIC,
      },
      {
        name: 'internship-view',
        title: 'Famulatur Detailansicht',
        component: StudentInternshipPage,
        path: '/internship/:id',
        auth: AuthLevel.PUBLIC,
      },
    ],
  },
  /* Auth and Register routes */
  {
    layout: ContainerLayout,
    routes: [
      {
        name: 'login',
        title: 'Login',
        component: Login,
        path: '/login',
        auth: AuthLevel.PUBLIC,
      },
      {
        name: 'logout',
        title: 'Logout',
        component: Logout,
        path: '/logout',
        auth: AuthLevel.STUDENT,
      },
      {
        name: 'password-reset',
        title: 'Passwort Zurücksetzen',
        component: PasswordReset,
        path: '/password-reset',
        auth: AuthLevel.PUBLIC,
      },
      {
        name: 'password-reset-token',
        title: 'Passwort Zurücksetzen',
        component: PasswordResetWithToken,
        path: '/password-reset/token',
        auth: AuthLevel.PUBLIC,
      },
      {
        name: 'register',
        title: 'Studenten Registrierung',
        component: Register,
        path: '/register',
        auth: AuthLevel.PUBLIC,
      },
      {
        name: 'needs-verification',
        title: 'Account Verifizierung ausständig',
        component: NeedsVerification,
        path: '/register/needs-verification',
        auth: AuthLevel.PUBLIC,
      },
      {
        name: 'verify',
        title: 'Account Verifizierung',
        component: Verify,
        path: '/register/verify',
        auth: AuthLevel.PUBLIC,
      },
    ],
  },
  /* Administration routes */
  {
    layout: AdministrationLayout,
    routes: [
      {
        name: 'organization',
        title: 'Organisation',
        routes: [
          {
            name: 'dashboard',
            title: 'Dashboard',
            component: Dashboard,
            path: '/organization',
            auth: AuthLevel.ORGANIZATION,
          },
          {
            name: 'calendar',
            title: 'Kalender',
            component: Calendar,
            path: '/organization/calendar',
            auth: AuthLevel.ORGANIZATION,
          },
          {
            name: 'internships',
            title: 'Stellenverwaltung',
            component: Internships,
            path: '/organization/internships',
            auth: AuthLevel.ORGANIZATION,
          },
          {
            name: 'internship-offers',
            title: 'Ausschreibungen',
            component: InternshipOffers,
            path: '/organization/internship-offers',
            auth: AuthLevel.ORGANIZATION,
          },
          {
            name: 'internship-offers',
            title: 'Ausschreibung erstellen',
            component: InternshipOfferCreate,
            path: '/organization/internship-offers/create',
            auth: AuthLevel.ORGANIZATION,
          },
          {
            name: 'internship-offer',
            title: 'Ausschreibung bearbeiten',
            component: InternshipOfferEdit,
            path: '/organization/internship-offers/:id',
            auth: AuthLevel.ORGANIZATION,
          },
          {
            name: 'administration',
            title: 'Verwaltung',
            component: Administration,
            path: '/organization/administration',
            auth: AuthLevel.ORGANIZATION,
          },
        ],
      },
    ],
  },
]

export const Routes = renderRoutes(routes)
