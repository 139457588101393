import { type RootState } from '../../../store'
import { type IApiState } from '../../models/ApiState'
import createApiEndpoint from '../../utils/createApiEndpoint'
import {
  type IChangePasswordResponse,
  type IChangePasswordRequest,
} from './changePasswordInterface'

/* Create selector */
const changePasswordEndpointSelector = (
  state: RootState,
): IApiState<IChangePasswordResponse> => state.api.changePassword

/* Create reducer and thunk */
const {
  reducer: changePasswordEndpointReducer,
  thunk: changePasswordEndpointThunk,
} = createApiEndpoint<IChangePasswordRequest, IChangePasswordResponse>({
  endpoint: '/change-password',
  method: 'POST',
  stateName: 'changePassword',
})

/* Export reducer, selector and thunk action */
export { changePasswordEndpointSelector, changePasswordEndpointThunk }
export default changePasswordEndpointReducer
