module.exports = {
  gray: {
    50: '#fbfbfb',
    100: '#f5f8f7',
    200: '#f0f3f2',
    300: '#ebeeed',
    400: '#cacdcc',
    500: '#acafae',
    600: '#828584',
    700: '#6d706f',
    800: '#4e504f',
    900: '#2c2e2d',
  },
  green: {
    50: '#F0F5EB',
    100: '#E2EDD8',
    200: '#B3CFA1',
    300: '#89B374',
    400: '#3E7A2F',
    500: '#0B4008',
    600: '#093B06',
    700: '#073004',
    800: '#042603',
    900: '#021C01',
  },
  yellow: {
    50: '#FFFDF7',
    100: '#FFFBF0',
    200: '#FFF5D9',
    300: '#FFEEC2',
    400: '#FFDA94',
    500: '#FFC067',
    600: '#E6A353',
    700: '#BF7F39',
    800: '#995B25',
    900: '#733C15',
  },
  red: {
    50: '#fef2f2',
    100: '#fee2e2',
    200: '#fecaca',
    300: '#fca5a5',
    400: '#f87171',
    500: '#ef4444',
    600: '#dc2626',
    700: '#b91c1c',
    800: '#991b1b',
    900: '#7f1d1d',
    950: '#450a0a',
  },
  white: '#ffffff',
}
