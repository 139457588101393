import React, { useCallback } from 'react'
import { type UseFormRegisterReturn } from 'react-hook-form'
import FamulenzInputBase, {
  type IFamulenzInputBaseProps,
} from '../InputBase/FamulenzInputBase'
import classNames from 'classnames'
import styles from './FamulenzTextArea.module.scss'

interface IProps extends Omit<IFamulenzInputBaseProps, 'children'> {
  // The placeholder for the input field
  placeholder: string
  // The default value of the input field
  defaultValue?: string
  // Event handler for listening to the changed input value
  // (Note: This could also be done via the watch function of the react-hook-form library.)
  onValueChange?: (value: string) => void
  // The autoComplete attribute of the input field
  autoComplete?: string
  // Return from the register function of react-hook-form used for validation
  register?: UseFormRegisterReturn
  // The actual error message
  error?: string
  // The number of rows
  rows?: number
}

export default function FamulenzTextArea(props: IProps): React.JSX.Element {
  /* Callbacks */
  const hasError = useCallback(() => {
    return props.error !== undefined && props.error !== ''
  }, [props.error])

  return (
    <FamulenzInputBase {...props}>
      <textarea
        {...props.register}
        id={props.id}
        className={classNames('form-input', styles.input, {
          [styles.inputError]: hasError(),
          [styles.inputSuccess]: !hasError(),
        })}
        placeholder={props.placeholder}
        required={props.required}
        onChange={e => {
          props.register?.onChange(e)
          if (props.onValueChange !== undefined) {
            props.onValueChange(e.target.value)
          }
        }}
        autoComplete={props.autoComplete}
        rows={props.rows ?? 4}
        defaultValue={props.defaultValue}
      />
    </FamulenzInputBase>
  )
}
