import type { RootState } from '../../../../store'
import type { IApiState } from '../../../models/ApiState'
import createApiEndpoint from '../../../utils/createApiEndpoint'
import type {
  IUsersLogoutRequest,
  IUsersLogoutResponse,
} from './usersLogoutInterface'

/* Create selector */
const usersLogoutEndpointSelector = (
  state: RootState,
): IApiState<IUsersLogoutResponse> => state.api.usersLogout

/* Create reducer and thunk */
const { reducer: usersLogoutEndpointReducer, thunk: usersLogoutEndpointThunk } =
  createApiEndpoint<IUsersLogoutRequest, IUsersLogoutResponse>({
    endpoint: '/users/logout',
    method: 'GET',
    stateName: 'usersLogout',
  })

/* Export reducer, selector and thunk action */
export { usersLogoutEndpointSelector, usersLogoutEndpointThunk }
export default usersLogoutEndpointReducer
