import type {
  IOrganizationInternshipOffersGetRequest,
  IOrganizationInternshipOffersGetResponse,
} from './organizationInternshipOffersGetInterface'
import createApiEndpoint from 'app/api/utils/createApiEndpoint'
import type { RootState } from 'app/store'
import type { IApiState } from 'app/api/models/ApiState'

/* Create selector */
const organizationInternshipOffersGetEndpointSelector = (
  state: RootState,
): IApiState<IOrganizationInternshipOffersGetResponse> =>
  state.api.organizationInternshipOffersGet

/* Create reducer and thunk */
const {
  reducer: organizationInternshipOffersGetEndpointReducer,
  thunk: organizationInternshipOffersGetEndpointThunk,
} = createApiEndpoint<
  IOrganizationInternshipOffersGetRequest,
  IOrganizationInternshipOffersGetResponse
>({
  endpoint: '/internship-offers/{id}',
  method: 'GET',
  stateName: 'organizationInternshipOffersGet',
})

/* Export reducer, selector and thunk action */
export {
  organizationInternshipOffersGetEndpointSelector,
  organizationInternshipOffersGetEndpointThunk,
}
export default organizationInternshipOffersGetEndpointReducer
