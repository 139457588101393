export interface IPaginatedResponse<ResultType> {
  next: number | null
  previous: number | null
  count: number
  results: ResultType[]
}

export interface IPaginatedRequest {
  page_size?: number
  page?: number
  ordering?: string
  search?: string
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}
