import { type UserType, type IUser, type IUserShort } from './User'

export type IUserTransformer<UserTemplate extends IUser | IUserShort> = {
  name: string
  initials: string
  type: UserType
} & UserTemplate

export default function userTransformer<
  UserTemplate extends IUser | IUserShort = IUser,
>(user: UserTemplate | null): IUserTransformer<UserTemplate> | null {
  if (user === null) {
    return null
  }

  return {
    ...user,
    name:
      (user.pre_title !== null ? `${user.pre_title} ` : '') +
      user.first_name +
      ' ' +
      user.last_name +
      (user.post_title !== null ? `, ${user.post_title}` : ''),
    initials: user.first_name.charAt(0) + user.last_name.charAt(0),
    type: user.user_type,
  }
}
