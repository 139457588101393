import React from 'react'
import ColorGradientText from 'components/layout/ColorGradientText/ColorGradientText'
import StudentQuickSearchBlock from 'components/layout/Student/QuickSearchBlock/StudentQuickSearchBlock'
import StudentContent from 'components/layout/Student/Content/StudentContent'
import FamulenzNewInternships from 'components/layout/Student/InternshipRecommendations/NewInternships/FamulenzNewInternships'
import FamulenzLastChanceInternships from 'components/layout/Student/InternshipRecommendations/LastChanceInternships/LastChanceInternships'
import FamulenzHr from 'components/layout/Hr/FamulenzHr'

export default function Index(): React.JSX.Element {
  return (
    <>
      <StudentQuickSearchBlock />

      <StudentContent
        id="recommendations-section"
        title={
          <>
            Lass dich <ColorGradientText>inspirieren</ColorGradientText>
          </>
        }
        subtitle="Noch keine Vorstellungen? Kein Problem! Stöbere einfach durch unsere Kategorien und lass dich inspirieren. Lorem ipsum dolor sit amet,
        consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
      >
        <>
          <FamulenzNewInternships />

          <FamulenzHr />

          <FamulenzLastChanceInternships />
        </>
      </StudentContent>
    </>
  )
}
