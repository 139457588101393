import { type RootState } from '../../../../store'
import { type IApiState } from '../../../models/ApiState'
import createApiEndpoint from '../../../utils/createApiEndpoint'
import {
  type IUsersVerifyResponse,
  type IUsersVerifyRequest,
} from './usersVerifyInterface'

/* Create selector */
const usersVerifyEndpointSelector = (
  state: RootState,
): IApiState<IUsersVerifyResponse> => state.api.usersVerify

/* Create reducer and thunk */
const { reducer: usersVerifyEndpointReducer, thunk: usersVerifyEndpointThunk } =
  createApiEndpoint<IUsersVerifyRequest, IUsersVerifyResponse>({
    endpoint: '/users/verify/{code}',
    method: 'GET',
    stateName: 'usersVerify',
  })

/* Export reducer, selector and thunk action */
export { usersVerifyEndpointSelector, usersVerifyEndpointThunk }
export default usersVerifyEndpointReducer
