import React, { useCallback, useEffect, useState } from 'react'
import styles from './Logout.module.scss'
import FamulenzLoader from '../../components/control/Loader/FamulenzLoader'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { logoutUserAction } from '../../app/features/User/UserSlice'
import {
  usersLogoutEndpointSelector,
  usersLogoutEndpointThunk,
} from '../../app/api/endpoints/users/logout'
import { DEFAULT_ERROR_TEXT } from '../../app/constants/texts'
import { useNavigate } from 'react-router-dom'

export default function Logout(): React.JSX.Element {
  /* Hooks */
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  /* Selectors */
  const { error } = useAppSelector(usersLogoutEndpointSelector)

  /* States */
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    // Delay for 1 second ... simply looks nicer
    window.setTimeout(() => {
      // Dispatch the logout action
      dispatch(usersLogoutEndpointThunk({}))
        .then(() => {
          dispatch(logoutUserAction())
          navigate('/')
        })
        .catch(() => {
          setLoading(false)
        })
    }, 1000)
  }, [])

  const getInformationText = useCallback((): React.JSX.Element => {
    if (error === null) {
      return <p>Sie werden in Kürze weitergeleitet...</p>
    } else {
      return <p className={styles.error}>{DEFAULT_ERROR_TEXT}</p>
    }
  }, [error])

  return (
    <>
      <h1 className={styles.header}>Abmeldung</h1>

      {getInformationText()}

      {loading && (
        <div className={styles.loader}>
          <FamulenzLoader loading={true} />
        </div>
      )}
    </>
  )
}
