import React from 'react'
import styles from './InternshipOfferPreview.module.scss'
import classNames from 'classnames'

interface IProps {
  type: string
  station: string
  body: string
  parentOrganization: string
  contactPerson: string
  size?: 'sm' | 'md' | 'lg' | 'xl'
}

export default function InternshipOfferPreview(
  props: IProps,
): React.JSX.Element {
  return (
    <div
      className={classNames(styles.container, {
        'max-w-sm': props.size === 'sm',
        'max-w-md': props.size === 'md',
        'max-w-lg': props.size === 'lg',
        'max-w-xl': props.size === 'xl',
      })}
    >
      <div className={styles.content}>
        <p className={styles.title}>
          {props.type} {props.station}
        </p>
        <p className={styles.description}>{props.body}</p>
        <div className={styles.footer}>
          <p>
            {props.station} - {props.parentOrganization}
          </p>
          <p className={styles.duration}>
            Kontaktperson: {props.contactPerson}
          </p>
        </div>
      </div>
    </div>
  )
}
