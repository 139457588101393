import FamulenzGridLayout from 'components/layout/GridLayout/FamulenzGridLayout/FamulenzGridLayout'
import { type IFamulenzGridLayoutElement } from 'components/layout/GridLayout/FamulenzGridLayoutElement/FamulenzGridLayoutElement'
import OrganizationalUnitsTable from 'components/tables/OrganizationalUnitsTable/OrganizationalUnitsTable'
import React from 'react'
import { type Layouts } from 'react-grid-layout'
import { BsGear } from 'react-icons/bs'

export default function Administration(): React.JSX.Element {
  const layouts: Layouts = {
    xl: [{ i: 'administration', x: 0, y: 0, w: 12, h: 10 }],
  }

  const elements: IFamulenzGridLayoutElement[] = [
    {
      i: 'administration',
      element: <OrganizationalUnitsTable />,
      title: 'Verwaltung',
      fullHeight: true,
    },
  ]

  return (
    <>
      <FamulenzGridLayout
        layouts={layouts}
        elements={elements}
        breadcrumbEntries={[
          {
            text: 'Verwaltung',
            to: '/organization/administration',
            isRoot: true,
            icon: <BsGear />,
          },
        ]}
      />
    </>
  )
}
