import React from 'react'
import FamulenzInternshipRecommendations from '../FamulenzInternshipRecommendations'
import ColorGradientText from 'components/layout/ColorGradientText/ColorGradientText'
import FamulenzLink from 'components/control/Link/FamulenzLink'
import { toast } from 'react-toastify'
import { type IFamulenzCardWithImageProps } from 'components/layout/CardWithImage/FamulenzCardWithImage'

interface IProps {
  dataAosDelay?: number
}

export default function FamulenzNewInternships(
  props: IProps,
): React.JSX.Element {
  // TODO: load internships from API
  const recommendations: IFamulenzCardWithImageProps[] = [
    {
      title: 'Famulatur Sportmedizin',
      text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.',
      to: '/internship/1',
      buttonText: 'Mehr erfahren',
      image: {
        src: 'https://images.pexels.com/photos/2959588/pexels-photo-2959588.jpeg?auto=compress&cs=tinysrgb',
        alt: 'Placeholder image',
      },
    },
    {
      title: 'Famulatur Allgemein- und Viszeralchirurgie',
      text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore.',
      to: '/internship/1',
      buttonText: 'Mehr erfahren',
      image: {
        src: 'https://images.pexels.com/photos/668300/pexels-photo-668300.jpeg?auto=compress&cs=tinysrgb',
        alt: 'Placeholder image',
      },
    },
    {
      title: 'Famulatur Allgemein- und Viszeralchirurgie',
      text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore.',
      to: '/internship/1',
      buttonText: 'Mehr erfahren',
      image: {
        src: 'https://images.pexels.com/photos/1692693/pexels-photo-1692693.jpeg?auto=compress&cs=tinysrgb',
        alt: 'Placeholder image',
      },
    },
  ]

  return (
    <>
      <FamulenzInternshipRecommendations
        title={
          <>
            <ColorGradientText>Neu</ColorGradientText> mit dabei
          </>
        }
        subtext={
          <>
            Klingt spannend?{' '}
            <FamulenzLink
              onClick={() => {
                // TODO: implement
                toast.error('Not implemented yet')
              }}
            >
              Klicke hier
            </FamulenzLink>{' '}
            um weitere Famulaturen zu finden, die kürzlich hinzugefügt wurden.
          </>
        }
        recommendations={recommendations}
        dataAosDelay={props.dataAosDelay ?? 0}
        mdCols={1}
        lgCols={3}
      />
    </>
  )
}
