import React from 'react'
import styles from './AdministrationSideNav.module.scss'
import { useNavigate } from 'react-router-dom'

interface ISideNavEntry {
  text: string
  icon: React.JSX.Element
  to: string
}

interface IProps {
  footer?: any
  entries: ISideNavEntry[]
}

export default function AdministrationSideNav(
  props: IProps,
): React.JSX.Element {
  /* Hooks */
  const navigate = useNavigate()

  return (
    <div className={styles.container}>
      <div className={styles.sideNav}>
        {/* Render the entries */}
        {props.entries.map((entry, index) => (
          <div
            key={index}
            className={styles.entry}
            onClick={() => {
              navigate(entry.to)
            }}
          >
            <div className={styles.link}>
              <span className={styles.icon}>{entry.icon}</span>
              <span className={styles.text}>{entry.text}</span>
            </div>
          </div>
        ))}

        {/* Footer */}
        <div className={styles.footer}>{props.footer}</div>
      </div>
    </div>
  )
}
