import React from 'react'
import FamulenzCardWithImage, {
  type IFamulenzCardWithImageProps,
} from 'components/layout/CardWithImage/FamulenzCardWithImage'
import styles from './FamulenzInternshipRecommendations.module.scss'
import _uniqueId from 'lodash/uniqueId'
import classNames from 'classnames'

interface IProps {
  title: React.JSX.Element | string
  subtext: React.JSX.Element | string
  recommendations: IFamulenzCardWithImageProps[]
  dataAosDelay?: number
  mdCols?: 1 | 2 | 3 | 4
  lgCols?: 1 | 2 | 3 | 4
  noMarginBottom?: boolean
}

export default function FamulenzInternshipRecommendations(
  props: IProps,
): React.JSX.Element {
  const id = _uniqueId('recommendations-')

  return (
    <div
      id={id}
      className={classNames(styles.container, {
        [styles.noMarginBottom]: props.noMarginBottom === true,
      })}
    >
      <div
        className={styles.titleContainer}
        data-aos="fade-up"
        data-aos-delay={props.dataAosDelay ?? 0}
      >
        <h3 className="h3">{props.title}</h3>
      </div>

      <div
        className={classNames(styles.recommendationsContainer, {
          // Note: Has to be done like this, otherwise classes are not rendered correctly somehow
          [styles.lgCols1]: props.lgCols === 1,
          [styles.lgCols2]: props.lgCols === 2,
          [styles.lgCols3]: props.lgCols === 3,
          [styles.lgCols4]: props.lgCols === 4,
          [styles.mdCols1]: props.mdCols === 1,
          [styles.mdCols2]: props.mdCols === 2,
          [styles.mdCols3]: props.mdCols === 3,
          [styles.mdCols4]: props.mdCols === 4,
        })}
      >
        {props.recommendations.map((recommendation, index) => {
          return (
            <FamulenzCardWithImage
              key={index}
              {...recommendation}
              dataAosDelay={(props.dataAosDelay ?? 0) + 200 * (index + 1)}
              daatAosAnchor={`#${id}`}
            />
          )
        })}
      </div>

      <div
        className={styles.subtextContainer}
        data-aos="fade-up"
        data-aos-delay={
          (props.dataAosDelay ?? 0) + (props.recommendations.length + 1) * 200
        }
        data-aos-anchor={`#${id}`}
      >
        <span>{props.subtext}</span>
      </div>
    </div>
  )
}
