import React, { useCallback, useEffect, useState } from 'react'
import FamulenzInputBase, {
  type IFamulenzInputBaseProps,
} from '../InputBase/FamulenzInputBase'
import Select, { type ThemeConfig } from 'react-select'
import CreateableSelect from 'react-select/creatable'
import colors from 'styles/colors'

export type FamuzlenzSelectOptionValue = string | number

export interface IFamulenzSelectOption {
  value: FamuzlenzSelectOptionValue
  label: string
}

export interface IFamulenzSelectProps
  extends Omit<IFamulenzInputBaseProps, 'children'> {
  // The placeholder for the input field
  placeholder: string
  // Defines whether the input field is disabled
  disabled?: boolean
  // Defines whether the input field is searchable
  searchable?: boolean
  // Defines whether the input field is clearable
  clearable?: boolean
  // Defines whether the input field is loading
  loading?: boolean
  // Defines whether the select is multi-select
  isMulti?: boolean
  // If defined, the select is creatable and onCreateOption is called upon creation of a value
  onCreateOption?: (val: string) => IFamulenzSelectOption
  // The value of the input field
  // TODO: replace with default value, since the value should not be set like this
  value?: FamuzlenzSelectOptionValue | FamuzlenzSelectOptionValue[] | null
  // The options for the select
  options: IFamulenzSelectOption[]
  // Event handler for listening to the changed input value
  // (Note: This could also be done via the watch function of the react-hook-form library.)
  onChange?: (
    value: FamuzlenzSelectOptionValue[] | FamuzlenzSelectOptionValue | null,
  ) => void
  // The actual error message
  error?: string
}

export default function FamulenzSelect(
  props: IFamulenzSelectProps,
): React.JSX.Element {
  /* Callbacks */
  const hasError = useCallback(() => {
    return props.error !== undefined && props.error !== ''
  }, [props.error])

  /* States */
  const [Component, setComponent] = useState<Select | CreateableSelect>(Select)
  const [selectedOptions, setSelectedOptions] = useState<
    IFamulenzSelectOption[] | null
  >(null)

  /* Returns the respective option for a given value */
  const getOption = (
    value: FamuzlenzSelectOptionValue,
  ): IFamulenzSelectOption | null => {
    // Search for the value in the options
    for (let i = 0; i < props.options.length; i++) {
      if (props.options[i].value === value) {
        return props.options[i]
      }
    }

    return null
  }

  /* Change the selected option when the value changes */
  useEffect(() => {
    if (props.value === undefined || props.value === null) {
      setSelectedOptions(null)
    }

    if (Array.isArray(props.value)) {
      const options: IFamulenzSelectOption[] = []
      props.value.forEach((element: FamuzlenzSelectOptionValue) => {
        const option = getOption(element)
        if (option !== null) {
          options.push(option)
        }
      })
      setSelectedOptions(options)
    } else {
      const option = getOption(props.value as FamuzlenzSelectOptionValue)
      if (option !== null) {
        setSelectedOptions([option])
      }
    }
  }, [props.value, props.options])

  /* The theme for the select to match the Famulenz brand */
  const theme: ThemeConfig = base => {
    return {
      ...base,
      borderRadius: 0.75,
      colors: {
        ...base.colors,
        primary: colors.green[400],
        primary25: colors.green[200],
        primary50: colors.green[300],
        primary75: colors.green[500],
        danger: colors.yellow[700],
        dangerLight: colors.yellow[400],
        neutral0: colors.white,
        neutral5: colors.gray[100],
        neutral10: colors.gray[200],
        neutral20: colors.gray[500],
        neutral30: colors.gray[600],
        neutral40: colors.gray[400],
        neutral50: colors.gray[500],
        neutral60: colors.gray[600],
        neutral70: colors.gray[700],
        neutral80: colors.gray[800],
        neutral90: colors.gray[900],
      },
    }
  }

  // Set the corresponding component
  useEffect((): void => {
    if (props.onCreateOption === undefined) {
      setComponent(Select)
    } else {
      setComponent(CreateableSelect)
    }
  }, [props.onCreateOption])

  // Handle the change of the select
  const handleChange = (options: any): void => {
    setSelectedOptions(options)

    if (props.onChange !== undefined) {
      if (props.isMulti === true) {
        const values: FamuzlenzSelectOptionValue[] = []
        options.forEach((element: IFamulenzSelectOption) => {
          values.push(element.value)
        })
        props.onChange(values)
      } else {
        if (options === null) {
          props.onChange(null)
        } else {
          props.onChange(options.value)
        }
      }
    }
  }

  return (
    <FamulenzInputBase {...props}>
      <Component
        className="famulenz-select-container"
        classNamePrefix="famulenz-select"
        menuPortalTarget={document.body}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: '0.75rem',
            boxShadow: 'none',
            borderColor: hasError() ? colors.red[500] : colors.gray[500],
            '&:hover': {
              borderColor: hasError() ? colors.red[500] : colors.gray[500],
              cursor: 'pointer',
            },
            minHeight: '2.625rem',
          }),
          multiValue: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: '0.75rem',
          }),
          menuPortal: (baseStyles, state) => ({
            ...baseStyles,
            zIndex: 9999,
          }),
        }}
        placeholder={props.placeholder}
        isDisabled={(props.disabled ?? false) || props.loading}
        isSearchable={props.searchable ?? true}
        isClearable={props.clearable ?? true}
        isLoading={props.loading ?? false}
        isMulti={props.isMulti ?? false}
        theme={theme}
        noOptionsMessage={() => 'Keine Optionen'}
        loadingMessage={() => 'Laden...'}
        onCreateOption={props.onCreateOption}
        onChange={e => {
          handleChange(e)
        }}
        options={props.options}
        value={selectedOptions}
      />
    </FamulenzInputBase>
  )
}
