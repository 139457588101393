import StudentFooter from 'components/layout/Student/Footer/StudentFooter'
import React from 'react'
import { Outlet } from 'react-router'
import styles from './StudentLayout.module.scss'
import StudentHeader from 'components/layout/Student/Header/StudentHeader'

export default function StudentLayout(): React.JSX.Element {
  return (
    <div className={styles.container}>
      <StudentHeader />

      <div className={styles.content}>
        <div className={styles.contentInner}>
          <Outlet />
        </div>
      </div>

      <StudentFooter />
    </div>
  )
}
