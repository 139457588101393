import {
  organizationInternshipOffersGetEndpointSelector,
  organizationInternshipOffersGetEndpointThunk,
} from 'app/api/endpoints/organization/internship-offers/get'
import { type IOrganizationInternshipOffersGetRequest } from 'app/api/endpoints/organization/internship-offers/get/organizationInternshipOffersGetInterface'
import { LoadingState } from 'app/api/models/LoadingState'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import LoaderWrapper from 'components/control/LoaderWrapper/LoaderWrapper'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import internshipOfferTransformer, {
  getTypeNameFromInternshipOfferType,
} from 'app/models/InternshipOffer/InternshipOffer.transformer'
import { useForm } from 'react-hook-form'
import FamulenzInput from 'components/control/Input/FamulenzInput'
import styles from './InternshipOfferForm.module.scss'
import FamulenzTextArea from 'components/control/TextArea/FamulenzTextArea'
import FamulenzMultiStepProcess from 'components/control/MultiStepProcess/FamulenzMultiStepProcess'
import {
  DEFAULT_ERROR_TEXT,
  FIELD_REQUIRED_ERROR_TEXT,
  GREATER_ZERO,
} from 'app/constants/texts'
import {
  InternshipType,
  type IInternshipOffer,
  type IInternshipOfferEdit,
  type IInternshipOfferCreate,
} from 'app/models/InternshipOffer/InternshipOffer'
import FamulenzSelectControllable from 'components/control/SelectControllable/FamulenzSelectControllable'
import {
  organizationOrganizationalUnitsUsableUsersEndpointSelector,
  organizationOrganizationalUnitsUsableUsersEndpointThunk,
} from 'app/api/endpoints/organization/organizational-units/usable_users'
import { type IPaginatedRequest } from 'app/models/Pagination/Pagination'
import userTransformer from 'app/models/User/User.transformer'
import FamulenzToggle from 'components/control/Toggle/FamulenzToggle'
import InternshipOfferPreview from 'components/layout/InternshipOfferPreview/InternshipOfferPreview'
import {
  organizationOrganizationalUnitsOwnEndpointSelector,
  organizationOrganizationalUnitsOwnEndpointThunk,
} from 'app/api/endpoints/organization/organizational-units/own'
import organizationalUnitTransformer from 'app/models/OrganizationalUnit/OrganizationalUnit.transformer'
import { OrganizationalUnitType } from 'app/models/OrganizationalUnit/OrganizationalUnit'
import { toast } from 'react-toastify'
import {
  organizationInternshipOffersEditEndpointSelector,
  organizationInternshipOffersEditEndpointThunk,
} from 'app/api/endpoints/organization/internship-offers/edit'
import {
  organizationInternshipOffersCreateEndpointSelector,
  organizationInternshipOffersCreateEndpointThunk,
} from 'app/api/endpoints/organization/internship-offers/create'

export enum InternshipOfferComponentMode {
  EDIT = 'edit',
  CREATE = 'create',
}

interface IInternshipOfferForm {
  // First stage
  organizational_unit: number
  internship_type: InternshipType
  max_students_in_same_time: number
  max_days: number
  min_days: number

  // Second stage
  public_description: string
  required_documents: string
  private_description: string

  // Third stage
  main_contact_person: number
  watcher: number[]

  // Fourth stage
  active: boolean
}

interface IProps {
  mode: InternshipOfferComponentMode
}

export default function InternshipOfferForm(props: IProps): React.JSX.Element {
  /* Hooks */
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    trigger,
    watch,
    reset,
    formState: { errors },
  } = useForm<IInternshipOfferForm>({ mode: 'onChange' })

  /* Load the intnerhsip data if the id is provided or reset the state if the mode is create */
  useEffect(() => {
    if (props.mode === InternshipOfferComponentMode.EDIT) {
      /* Load internship offer from API */
      const data: IOrganizationInternshipOffersGetRequest = {
        id: parseInt(id ?? 'null'),
      }
      dispatch(organizationInternshipOffersGetEndpointThunk(data))
        .unwrap()
        .then((res): void => {
          if (res !== undefined) {
            const loadedInternshipOffer: IInternshipOffer = res
            updateInternshipOffer(loadedInternshipOffer)
          }
        })
        .catch()
    } else if (props.mode === InternshipOfferComponentMode.CREATE) {
      /* When in create mode, reset the internship Offer */
      reset()
    }

    // On unmount, reset the internship offer
    return () => {
      reset()
    }
  }, [])
  const { loading, error } = useAppSelector(
    organizationInternshipOffersGetEndpointSelector,
  )

  /* Set the new internship as form data when the payload arrives */
  const updateInternshipOffer = (data: IInternshipOffer): void => {
    setValue('organizational_unit', data.organizational_unit.id)
    setValue('internship_type', data.internship_type)
    setValue('max_students_in_same_time', data.max_students_in_same_time)
    setValue('max_days', data.max_days)
    setValue('min_days', data.min_days)
    setValue('public_description', data.public_description)
    setValue('required_documents', data.required_documents)
    setValue('private_description', data.private_description)
    setValue('main_contact_person', data.main_contact_person.id)
    setValue('watcher', internshipOfferTransformer(data)?.watcherIds ?? [])
    setValue('active', data.active)
  }

  /* Load the usable users */
  useEffect(() => {
    const data: IPaginatedRequest = {
      page_size: 10000,
    }
    dispatch(organizationOrganizationalUnitsUsableUsersEndpointThunk(data))
  }, [])
  const {
    payload: usersPayload,
    loading: usersLoading,
    error: usersError,
  } = useAppSelector(organizationOrganizationalUnitsUsableUsersEndpointSelector)
  const usableUsers = usersPayload?.results.map(userTransformer) ?? []

  /* Load the organizational units */
  useEffect(() => {
    const data: IPaginatedRequest = {
      page_size: 10000,
    }
    dispatch(organizationOrganizationalUnitsOwnEndpointThunk(data))
  }, [])
  const {
    payload: organizationalUnitsPayload,
    loading: organizationalUnitsLoading,
    error: organizationalUnitsError,
  } = useAppSelector(organizationOrganizationalUnitsOwnEndpointSelector)
  const organizationalUnits =
    organizationalUnitsPayload?.results.map(organizationalUnitTransformer) ?? []

  /* Handle the submission of the form */
  const handleFormSubmit = (data: IInternshipOfferForm): void => {
    if (props.mode === InternshipOfferComponentMode.EDIT) {
      /* In edit mode */
      const requestData: IInternshipOfferEdit = {
        ...data,
        id: parseInt(id ?? 'null'),
      }
      dispatch(organizationInternshipOffersEditEndpointThunk(requestData))
        .unwrap()
        .then(() => {
          toast.success('Ausschreibung erfolgreich bearbeitet!')
          navigate('/organization/internship-offers')
        })
        .catch(() => {
          toast.error(DEFAULT_ERROR_TEXT)
        })
    } else if (props.mode === InternshipOfferComponentMode.CREATE) {
      const requestData: IInternshipOfferCreate = data
      dispatch(organizationInternshipOffersCreateEndpointThunk(requestData))
        .unwrap()
        .then(() => {
          toast.success('Ausschreibung erfolgreich erstellt!')
          navigate('/organization/internship-offers')
        })
        .catch(() => {
          toast.error(DEFAULT_ERROR_TEXT)
        })
    }
  }

  /* Selectors for edit and create endpoint */
  const { loading: editLoading } = useAppSelector(
    organizationInternshipOffersEditEndpointSelector,
  )
  const { loading: createLoading } = useAppSelector(
    organizationInternshipOffersCreateEndpointSelector,
  )

  return (
    <>
      <LoaderWrapper loading={loading === LoadingState.Pending} error={error}>
        <FamulenzMultiStepProcess<IInternshipOfferForm>
          handleSubmit={handleSubmit}
          getValues={getValues}
          trigger={trigger}
          loading={
            editLoading === LoadingState.Pending ||
            createLoading === LoadingState.Pending
          }
          onSubmit={handleFormSubmit}
          steps={[
            {
              key: 'basic-information',
              description:
                'Bitte tragen Sie zuerst die grundlegenden Informationen zur Ausschreibung ein.',
              triggerFields: [
                'organizational_unit',
                'internship_type',
                'max_students_in_same_time',
                'max_days',
                'min_days',
              ],
              content: (
                <>
                  <FamulenzSelectControllable<IInternshipOfferForm>
                    id="stationSelect"
                    placeholder="Station auswählen..."
                    label="Station"
                    description="Bitte wählen Sie die Station aus, für die die Ausschreibung erstellt werden soll."
                    options={organizationalUnits
                      .filter(
                        ou =>
                          ou !== null &&
                          ou?.ou_type === OrganizationalUnitType.STATION,
                      )
                      .map(ou => ({
                        label: ou?.nameWithParent ?? '',
                        value: ou?.id ?? 0,
                      }))}
                    clearable
                    searchable
                    name="organizational_unit"
                    control={control}
                    rules={{ required: FIELD_REQUIRED_ERROR_TEXT }}
                    error={errors?.organizational_unit?.message}
                    required
                    loading={
                      organizationalUnitsLoading === LoadingState.Pending
                    }
                  />

                  {organizationalUnitsError !== null && (
                    <p className={styles.error}>
                      Die verfügbaren Stationen konnten nicht geladen werden,
                      versuchen Sie es später erneut.
                    </p>
                  )}

                  <FamulenzSelectControllable<IInternshipOfferForm>
                    id="internshipTypeSelect"
                    placeholder="Typ der Ausschreibung auswählen..."
                    label="Typ"
                    description="Zurzeit werden nur Famulaturen als Ausschreibungen unterstützt."
                    options={[
                      { value: InternshipType.FAMULATUR, label: 'Famulatur' },
                    ]}
                    defaultValue={InternshipType.FAMULATUR}
                    clearable={false}
                    searchable={false}
                    name="internship_type"
                    control={control}
                    rules={{ required: FIELD_REQUIRED_ERROR_TEXT }}
                    error={errors?.internship_type?.message}
                  />

                  <FamulenzInput
                    label="Maximal gleichzeitige Auszubildende"
                    id="max-students-in-same-time"
                    description="Die Anzahl der auszubildenden Personen, die diese Ausschreibung zur gleichen Zeit belegen können."
                    placeholder="2"
                    register={register('max_students_in_same_time', {
                      required: FIELD_REQUIRED_ERROR_TEXT,
                      min: {
                        value: 1,
                        message: GREATER_ZERO,
                      },
                    })}
                    type="number"
                    error={errors?.max_students_in_same_time?.message}
                    required
                  />

                  <div className={styles.grid2}>
                    <FamulenzInput
                      label="Mindestdauer"
                      id="min-duration-input"
                      description="Die Mindestdauer der jeweiligen Stelle in Tagen."
                      placeholder="14"
                      register={register('min_days', {
                        required: FIELD_REQUIRED_ERROR_TEXT,
                        min: {
                          value: 1,
                          message: GREATER_ZERO,
                        },
                        validate: value => {
                          if (
                            Number(value) > 0 &&
                            Number(getValues('max_days')) > 0 &&
                            Number(value) > Number(getValues('max_days'))
                          ) {
                            return 'Die Mindestdauer darf nicht größer als die Maximaldauer sein.'
                          }
                        },
                      })}
                      type="number"
                      error={errors?.min_days?.message}
                      required
                    />

                    <FamulenzInput
                      label="Maximaldauer"
                      id="max-duration-input"
                      description="Die Maximaldauer der jeweiligen Stelle in Tagen."
                      placeholder="28"
                      register={register('max_days', {
                        required: FIELD_REQUIRED_ERROR_TEXT,
                        min: {
                          value: 1,
                          message: GREATER_ZERO,
                        },
                        validate: value => {
                          if (
                            Number(value) > 0 &&
                            Number(getValues('min_days')) > 0 &&
                            Number(value) < Number(getValues('min_days'))
                          ) {
                            return 'Die Maximaldauer darf nicht kleiner als die Minimaldauer sein.'
                          }
                        },
                      })}
                      type="number"
                      error={errors?.max_days?.message}
                      required
                    />
                  </div>
                </>
              ),
            },
            {
              key: 'notes',
              description:
                'Bitte tragen Sie nun eine etwas genauere Beschreibung zur Ausschreibung ein.',
              triggerFields: [
                'public_description',
                'required_documents',
                'private_description',
              ],
              content: (
                <>
                  <FamulenzTextArea
                    label="Öffentliche Beschreibung"
                    id="public-description"
                    description="Eine kurze Beschreibung zur Ausschreibung, die öffentlich angezeigt wird."
                    placeholder="Was gibt es spannendes zu lernen?"
                    register={register('public_description', {
                      required: FIELD_REQUIRED_ERROR_TEXT,
                    })}
                    error={errors?.public_description?.message}
                    required
                  />

                  <FamulenzTextArea
                    label="Benötigte Dokumente"
                    id="public-description"
                    description="Hier können Sie angeben, welche Dokumente bei einer Bewerbung mit hochgeladen werden müssen."
                    placeholder={'Famulaturlizenz\nImmunitätsnachweis\n...'}
                    register={register('required_documents', {
                      required: FIELD_REQUIRED_ERROR_TEXT,
                    })}
                    error={errors?.required_documents?.message}
                    required
                  />

                  <FamulenzTextArea
                    label="Private Notizen"
                    id="private-description"
                    description="Hierbei handelt es sich um Notizen, die nur für autorisiertes Personal sichtbar sind."
                    placeholder="Welche internen Informationen gibt es?"
                    register={register('private_description', {
                      required: false,
                    })}
                    error={errors?.private_description?.message}
                  />
                </>
              ),
            },
            {
              key: 'persons',
              description:
                'Bitte tragen Sie nun die für die Ausschreibung zuständigen Personen ein.',
              triggerFields: ['main_contact_person', 'watcher'],
              content: (
                <>
                  <FamulenzSelectControllable<IInternshipOfferForm>
                    id="contact-person-select"
                    placeholder="Kontaktperson auswählen..."
                    label="Kontaktperson"
                    description="Bitte wählen Sie die Kontaktperson für die Ausschreibung aus."
                    options={usableUsers
                      .filter(user => user !== null)
                      .map(user => ({
                        label: user?.name ?? '',
                        value: user?.id ?? 0,
                      }))}
                    loading={usersLoading === LoadingState.Pending}
                    clearable
                    searchable
                    name="main_contact_person"
                    control={control}
                    rules={{ required: FIELD_REQUIRED_ERROR_TEXT }}
                    error={errors?.main_contact_person?.message}
                    required
                  />

                  {usersError !== null && (
                    <p className={styles.error}>
                      Die verfügbaren User konnten nicht geladen werden,
                      versuchen Sie es später erneut.
                    </p>
                  )}

                  <FamulenzSelectControllable<IInternshipOfferForm>
                    id="wacther-person-select"
                    placeholder="Beobachter auswählen..."
                    label="Beobachter"
                    description="Beobachter einer Ausschreibung werden automatisch über wichtige Updates, die die Ausschreibung betreffen, informiert. Beispielsweise, wenn jemand über die Ausschreibung bei einer Stelle beginnt."
                    options={usableUsers
                      .filter(user => user !== null)
                      .map(user => ({
                        label: user?.name ?? '',
                        value: user?.id ?? 0,
                      }))}
                    clearable
                    searchable
                    isMulti
                    name="watcher"
                    control={control}
                    error={errors?.watcher?.message}
                  />
                </>
              ),
            },
            {
              key: 'preview',
              description:
                'Hier finden Sie eine Vorschau Ihrer soeben erstellen Ausschreibung.',
              triggerFields: ['active'],
              content: (
                <>
                  <InternshipOfferPreview
                    type={getTypeNameFromInternshipOfferType(
                      watch('internship_type'),
                    )}
                    station={
                      organizationalUnits.find(
                        ou => ou?.id === watch('organizational_unit'),
                      )?.name ?? ''
                    }
                    body={watch('public_description')}
                    parentOrganization={
                      organizationalUnits.find(
                        ou => ou?.id === watch('organizational_unit'),
                      )?.parents[0]?.name ?? ''
                    }
                    contactPerson={
                      usableUsers.find(
                        user => user?.id === watch('main_contact_person'),
                      )?.name ?? ''
                    }
                    size="lg"
                  />

                  <FamulenzToggle
                    id="public-toggle"
                    label="Öffentlich"
                    description="Hiermit kann festgelegt werden, ob die Ausschreibung öffentlich sichtbar ist."
                    register={register('active')}
                    error={errors?.active?.message}
                    activeText="Ausschreibung öffentlich"
                    inactiveText="Ausschreibung nicht öffentlich"
                  />
                </>
              ),
            },
          ]}
        />
      </LoaderWrapper>
    </>
  )
}
