import FamulenzButton from 'components/control/Button/FamulenzButton'
import FamulenzContinueScrollButton from 'components/control/ContinueScrollButton/FamulenzContinueScrollButton'
import FamulenzLink from 'components/control/Link/FamulenzLink'
import React from 'react'
import StudentContent from '../Content/StudentContent'
import ColorGradientText from 'components/layout/ColorGradientText/ColorGradientText'
import FamulenzSelect from 'components/control/Select/FamulenzSelect'
import { toast } from 'react-toastify'
import styles from './StudentQuickSearchBlock.module.scss'
import { scrollToElement } from 'components/utils/scrollToElement'

export default function StudentQuickSearchBlock(): React.JSX.Element {
  /* Handle the scroll when clicking on the button */
  const handleScroll = (): void => {
    scrollToElement('recommendations-section')
  }

  return (
    <StudentContent
      id="quick-search-section"
      title={
        <>
          Finde <ColorGradientText>deine</ColorGradientText> Famulatur!
        </>
      }
      subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam."
      innerChildren={
        <>
          <div className={styles.controls}>
            <div className={styles.controlGrid}>
              <div
                data-aos="fade-right"
                data-aos-delay="400"
                data-aos-anchor="#quick-search-section"
              >
                <FamulenzSelect
                  id="time-selection"
                  placeholder="Wann?"
                  options={[
                    { value: '1', label: 'Jänner 2024' },
                    { value: '2', label: 'Februar 2024' },
                    { value: '3', label: 'März 2024' },
                    { value: '4', label: 'April 2024' },
                    { value: '5', label: 'Mai 2024' },
                    { value: '6', label: 'Juni 2024' },
                    { value: '7', label: 'Juli 2024' },
                    { value: '8', label: 'August 2024' },
                    { value: '9', label: 'September 2024' },
                    { value: '10', label: 'Oktober 2024' },
                    { value: '11', label: 'November 2024' },
                    { value: '12', label: 'Dezember 2024' },
                  ]}
                  clearable
                  searchable
                />
              </div>

              <div
                data-aos="fade-left"
                data-aos-delay="400"
                data-aos-anchor="#quick-search-section"
              >
                <FamulenzSelect
                  id="time-selection"
                  placeholder="Wo?"
                  options={[
                    { value: '1', label: 'Kärnten' },
                    { value: '2', label: 'Steiermark' },
                    { value: '3', label: 'Wien' },
                    { value: '4', label: 'Tirol' },
                    { value: '5', label: 'Salzburg' },
                    { value: '6', label: 'Niederösterreich' },
                    { value: '7', label: 'Oberösterreich' },
                    { value: '8', label: 'Burgenland' },
                    { value: '9', label: 'Vorarlberg' },
                  ]}
                  clearable
                  searchable
                />
              </div>
            </div>

            <div
              data-aos="fade-up"
              data-aos-delay="600"
              data-aos-anchor="#quick-search-section"
            >
              <FamulenzButton
                className="mt-4"
                onClick={() => {
                  // TODO: implement
                  toast.error('Not implemented yet')
                }}
              >
                Famulatur finden
              </FamulenzButton>
            </div>
          </div>

          <p
            className={styles.subtext}
            data-aos="fade-up"
            data-aos-delay="800"
            data-aos-anchor="#quick-search-section"
          >
            Noch keine Vorstellungen?{' '}
            <FamulenzLink onClick={handleScroll}>Klicke hier</FamulenzLink> um
            durch unsere Kategorien zu stöbern!
          </p>

          <FamulenzContinueScrollButton onClick={handleScroll} />
        </>
      }
      fullHeight
    />
  )
}
