import type {
  IOrganizationOrganizationalUnitsUsableUsersRequest,
  IOrganizationOrganizationalUnitsUsableUsersResponse,
} from './organizationOrganizationalUnitsUsableUsersInterface'
import createApiEndpoint from 'app/api/utils/createApiEndpoint'
import type { RootState } from 'app/store'
import type { IApiState } from 'app/api/models/ApiState'

/* Create selector */
const organizationOrganizationalUnitsUsableUsersEndpointSelector = (
  state: RootState,
): IApiState<IOrganizationOrganizationalUnitsUsableUsersResponse> =>
  state.api.organizationOrganizationalUnitsUsableUsers

/* Create reducer and thunk */
const {
  reducer: organizationOrganizationalUnitsUsableUsersEndpointReducer,
  thunk: organizationOrganizationalUnitsUsableUsersEndpointThunk,
} = createApiEndpoint<
  IOrganizationOrganizationalUnitsUsableUsersRequest,
  IOrganizationOrganizationalUnitsUsableUsersResponse
>({
  endpoint: '/organizational-units/usable_users',
  method: 'GET',
  stateName: 'organizationOrganizationalUnitsUsableUsers',
})

/* Export reducer, selector and thunk action */
export {
  organizationOrganizationalUnitsUsableUsersEndpointSelector,
  organizationOrganizationalUnitsUsableUsersEndpointThunk,
}
export default organizationOrganizationalUnitsUsableUsersEndpointReducer
