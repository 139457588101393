import type {
  IOrganizationInternshipOffersCreateRequest,
  IOrganizationInternshipOffersCreateResponse,
} from './organizationInternshipOffersCreateInterface'
import createApiEndpoint from 'app/api/utils/createApiEndpoint'
import type { RootState } from 'app/store'
import type { IApiState } from 'app/api/models/ApiState'

/* Create selector */
const organizationInternshipOffersCreateEndpointSelector = (
  state: RootState,
): IApiState<IOrganizationInternshipOffersCreateResponse> =>
  state.api.organizationInternshipOffersCreate

/* Create reducer and thunk */
const {
  reducer: organizationInternshipOffersCreateEndpointReducer,
  thunk: organizationInternshipOffersCreateEndpointThunk,
} = createApiEndpoint<
  IOrganizationInternshipOffersCreateRequest,
  IOrganizationInternshipOffersCreateResponse
>({
  endpoint: '/internship-offers',
  method: 'POST',
  stateName: 'organizationInternshipOffersCreate',
})

/* Export reducer, selector and thunk action */
export {
  organizationInternshipOffersCreateEndpointSelector,
  organizationInternshipOffersCreateEndpointThunk,
}
export default organizationInternshipOffersCreateEndpointReducer
