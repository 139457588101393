const scrollToElement = (id: string): void => {
  const elem: HTMLElement | null = document.getElementById(id)
  if (elem === null) return

  let yOffset = 0
  if (window.innerWidth < 768) {
    yOffset = -80
  }

  const y: number = elem.getBoundingClientRect().top + window.scrollY + yOffset
  window.scrollTo({ top: y, behavior: 'smooth' })
}

export { scrollToElement }
