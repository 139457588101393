const DEFAULT_ERROR_TEXT: string =
  'Das hat leider nicht funktioniert. Versuchen Sie es später erneut!'
const FIELD_REQUIRED_ERROR_TEXT: string = 'Dieses Feld ist erforderlich.'
const EMAIL_REQUIRED_ERROR_TEXT: string =
  'Eine gültige Email-Adresse ist erforderlich.'
const EMAIL_ALREADY_EXISTS_ERROR_TEXT: string =
  'Die Email-Adresse wird bereits verwendet.'
const PASSWORD_COMPLEXITY: string =
  'Das Passwort muss aus mindestens 8 Zeichen, mindestens einem Großbuchstaben, mindestens einem Kleinbuchstaben, mindestens einer Zahl, mindestens einem Sonderzeichen und keinem Leerzeichen bestehen.'
const PASSWORD_TOO_SIMPLE: string = 'Das Passwort ist zu einfach.'
const GREATER_ZERO: string = 'Der Wert muss größer als 0 sein.'

export {
  DEFAULT_ERROR_TEXT,
  FIELD_REQUIRED_ERROR_TEXT,
  EMAIL_REQUIRED_ERROR_TEXT,
  EMAIL_ALREADY_EXISTS_ERROR_TEXT,
  PASSWORD_COMPLEXITY,
  PASSWORD_TOO_SIMPLE,
  GREATER_ZERO,
}
