import React, { useEffect } from 'react'
import styles from './Verify.module.scss'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { LoadingState } from '../../../app/api/models/LoadingState'
import { DEFAULT_ERROR_TEXT } from '../../../app/constants/texts'
import FamulenzLink from '../../../components/control/Link/FamulenzLink'
import { useSearchParams } from 'react-router-dom'
import FamulenzLoader from '../../../components/control/Loader/FamulenzLoader'
import {
  usersVerifyEndpointSelector,
  usersVerifyEndpointThunk,
} from '../../../app/api/endpoints/users/verify'
import { type IUsersVerifyRequest } from '../../../app/api/endpoints/users/verify/usersVerifyInterface'

export default function Verify(): React.JSX.Element {
  /* Hooks */
  const [searchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const { loading, error } = useAppSelector(usersVerifyEndpointSelector)

  /* Check if the email is set in history state */
  useEffect(() => {
    const data: IUsersVerifyRequest = {
      code: searchParams.get('code') ?? '',
    }
    dispatch(usersVerifyEndpointThunk(data))
  }, [])

  return (
    <>
      <h1 className={styles.header}>Account Verifizierung</h1>

      {loading !== LoadingState.Success && (
        <>
          <p>Bitte warten Sie, während wir Ihren Account verifizieren.</p>

          <div className={styles.loader}>
            <FamulenzLoader loading={loading === LoadingState.Pending} />
          </div>

          {error !== null && (
            <p className={styles.error}>{DEFAULT_ERROR_TEXT}</p>
          )}
        </>
      )}

      {loading === LoadingState.Success && (
        <>
          <p>
            Vielen Dank! Ihr Account wurde erfolgreich verifiziert. Sie können
            sich nun einloggen.
          </p>
          <p>
            <FamulenzLink to="/login">Zum Login</FamulenzLink>
          </p>
        </>
      )}
    </>
  )
}
