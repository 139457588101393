import React, { useState } from 'react'
import { type UseFormRegisterReturn } from 'react-hook-form'
import FamulenzInputBase, {
  type IFamulenzInputBaseProps,
} from '../InputBase/FamulenzInputBase'
import styles from './FamulenzToggle.module.scss'
import classNames from 'classnames'

interface IProps extends Omit<IFamulenzInputBaseProps, 'children'> {
  // Event handler for listening to the changed input value
  // (Note: This could also be done via the watch function of the react-hook-form library.)
  onValueChange?: (value: boolean) => void
  // Return from the register function of react-hook-form used for validation
  register?: UseFormRegisterReturn
  // The actual error message
  error?: string
  // The default value
  defaultValue?: boolean
  // The text that is displayed when the toggle is active
  activeText: string
  // The text that is displayed when the toggle is inactive
  inactiveText: string
}

export default function FamulenzToggle(props: IProps): React.JSX.Element {
  /* States */
  const [value, setValue] = useState<boolean>(props.defaultValue ?? false)

  return (
    <FamulenzInputBase {...props}>
      <div className={styles.toggleContainer}>
        <label className={styles.toggleElement}>
          <input
            {...props.register}
            id={props.id}
            required={props.required}
            onChange={e => {
              props.register?.onChange(e)
              setValue(e.target.checked)
              if (props.onValueChange !== undefined) {
                props.onValueChange(e.target.checked)
              }
            }}
            type="checkbox"
            className="sr-only peer"
            defaultChecked={props.defaultValue}
          />
          <div
            className={classNames(
              styles.toggle,
              'peer',
              'peer-focus:outline-none',
              'peer-checked:after:translate-x-full',
              'rtl:peer-checked:after:-translate-x-full',
              'peer-checked:after:border-white',
              'peer-checked:bg-green-400',
            )}
          ></div>
          <span className={styles.text}>
            {value ? props.activeText : props.inactiveText}
          </span>
        </label>
      </div>
    </FamulenzInputBase>
  )
}
