import type { ILoginRequest, ILoginResponse } from './loginInterface'
import createApiEndpoint from 'app/api/utils/createApiEndpoint'
import type { RootState } from 'app/store'
import type { IApiState } from 'app/api/models/ApiState'

/* Create selector */
const loginEndpointSelector = (state: RootState): IApiState<ILoginResponse> =>
  state.api.login

/* Create reducer and thunk */
const { reducer: loginEndpointReducer, thunk: loginEndpointThunk } =
  createApiEndpoint<ILoginRequest, ILoginResponse>({
    endpoint: '/login',
    method: 'POST',
    stateName: 'login',
  })

/* Export reducer, selector and thunk action */
export { loginEndpointSelector, loginEndpointThunk }
export default loginEndpointReducer
