import classNames from 'classnames'
import React, { useCallback } from 'react'
import styles from './FamulenzCheckbox.module.scss'
import { type UseFormRegisterReturn } from 'react-hook-form'

interface IProps {
  // The id of the input field
  id?: string
  // The label for the input field
  label: any
  // Determines if the input field is required
  required?: boolean
  // Additional class name applied to the wrapper div
  className?: string
  // The default value of the input field
  defaultValue?: boolean
  // The onChange event handler (Note: This could also be done via the watch function of the react-hook-form library.)
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  // Return from the register function of react-hook-form used for validation
  register?: UseFormRegisterReturn
  // The actual error message
  error?: string
}

export default function FamulenzCheckbox(props: IProps): React.JSX.Element {
  const hasError = useCallback(() => {
    return props.error !== undefined && props.error !== ''
  }, [props.error])

  return (
    <>
      <label className={styles.label}>
        <input
          id={props.id}
          type="checkbox"
          // className="form-checkbox rounded-md"
          className={classNames('form-checkbox', styles.input, {
            'border-red-500': hasError(),
            'focus:border-red-500': hasError(),
            'focus:ring-red-500': hasError(),
            'focus:border-green-400': !hasError(),
            'focus:ring-green-400': !hasError(),
          })}
          onChange={props.onChange}
          defaultChecked={props.defaultValue}
          {...props.register}
        />
        <span className={styles.labelText}>
          {props.label}
          {(props.required ?? false) && (
            <>
              <span className={classNames({ 'text-red-600': hasError() })}>
                {' '}
                *
              </span>
            </>
          )}
        </span>
      </label>
      {hasError() && (
        <>
          <span className={styles.error}>{props.error}</span>
        </>
      )}
    </>
  )
}
