import FamulenzGridLayout from 'components/layout/GridLayout/FamulenzGridLayout/FamulenzGridLayout'
import { type IFamulenzGridLayoutElement } from 'components/layout/GridLayout/FamulenzGridLayoutElement/FamulenzGridLayoutElement'
import React from 'react'
import { type Layouts } from 'react-grid-layout'
import { BsCalendar2Week } from 'react-icons/bs'

export default function Calendar(): React.JSX.Element {
  const layouts: Layouts = {
    xl: [{ i: 'calendar', x: 0, y: 0, w: 12, h: 10 }],
  }

  const elements: IFamulenzGridLayoutElement[] = [
    {
      i: 'calendar',
      element: <p>Hier entsteht der Kalender.</p>,
      title: 'Kalender',
      fullHeight: true,
    },
  ]

  return (
    <>
      <FamulenzGridLayout
        layouts={layouts}
        elements={elements}
        breadcrumbEntries={[
          {
            text: 'Kalender',
            to: '/organization/calendar',
            isRoot: true,
            icon: <BsCalendar2Week />,
          },
        ]}
      />
    </>
  )
}
