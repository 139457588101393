import type {
  IOrganizationOrganizationalUnitsOwnRequest,
  IOrganizationOrganizationalUnitsOwnResponse,
} from './organizationOrganizationalUnitsOwnInterface'
import createApiEndpoint from 'app/api/utils/createApiEndpoint'
import type { RootState } from 'app/store'
import type { IApiState } from 'app/api/models/ApiState'

/* Create selector */
const organizationOrganizationalUnitsOwnEndpointSelector = (
  state: RootState,
): IApiState<IOrganizationOrganizationalUnitsOwnResponse> =>
  state.api.organizationOrganizationalUnitsOwn

/* Create reducer and thunk */
const {
  reducer: organizationOrganizationalUnitsOwnEndpointReducer,
  thunk: organizationOrganizationalUnitsOwnEndpointThunk,
} = createApiEndpoint<
  IOrganizationOrganizationalUnitsOwnRequest,
  IOrganizationOrganizationalUnitsOwnResponse
>({
  endpoint: '/organizational-units/own',
  method: 'GET',
  stateName: 'organizationOrganizationalUnitsOwn',
})

/* Export reducer, selector and thunk action */
export {
  organizationOrganizationalUnitsOwnEndpointSelector,
  organizationOrganizationalUnitsOwnEndpointThunk,
}
export default organizationOrganizationalUnitsOwnEndpointReducer
