import { type RootState } from '../../../../store'
import { type IApiState } from '../../../models/ApiState'
import createApiEndpoint from '../../../utils/createApiEndpoint'
import type {
  IRequestPasswordResetTokenRequest,
  IRequestPasswordResetTokenResponse,
} from './requestPasswordResetTokenInterface'

/* Create selector */
const requestPasswordResetTokenEndpointSelector = (
  state: RootState,
): IApiState<IRequestPasswordResetTokenResponse> =>
  state.api.requestPasswordResetToken

/* Create reducer and thunk */
const {
  reducer: requestPasswordResetTokenEndpointReducer,
  thunk: requestPasswordResetTokenEndpointThunk,
} = createApiEndpoint<
  IRequestPasswordResetTokenRequest,
  IRequestPasswordResetTokenResponse
>({
  endpoint: '/change-password/request_reset_token',
  method: 'GET',
  stateName: 'requestPasswordResetToken',
})

/* Export reducer, selector and thunk action */
export {
  requestPasswordResetTokenEndpointSelector,
  requestPasswordResetTokenEndpointThunk,
}
export default requestPasswordResetTokenEndpointReducer
