import { type IOrganizationalUnitShort } from '../OrganizationalUnit/OrganizationalUnit'
import { type IUser } from '../User/User'

export enum InternshipType {
  FAMULATUR = 1,
  KPJ = 2,
  HOSPITANT = 3,
  OTHER = 4,
}

export interface IInternshipOffer {
  id: number
  organizational_unit: IOrganizationalUnitShort
  internship_type: InternshipType
  public_description: string
  created_at: string
  private_description: string
  max_students_in_same_time: number
  main_contact_person: IUser
  required_documents: string
  min_days: number
  max_days: number
  watcher: IUser[]
  reminder_days_before_start: number
  active: boolean
}

export interface IInternshipOfferEdit {
  id: number
  public_description?: string
  private_description?: string
  organizational_unit?: number
  active?: boolean
  max_students_in_same_time?: number
  reminder_days_before_start?: number
  internship_type?: number
  required_documents?: string
  min_days?: number
  max_days?: number
}

export interface IInternshipOfferCreate {
  public_description?: string
  private_description?: string
  organizational_unit?: number
  active?: boolean
  max_students_in_same_time?: number
  reminder_days_before_start?: number
  internship_type?: number
  required_documents?: string
  min_days?: number
  max_days?: number
}
