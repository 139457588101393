import FamulenzHr from 'components/layout/Hr/FamulenzHr'
import StudentContent from 'components/layout/Student/Content/StudentContent'
import React from 'react'
import { useParams } from 'react-router-dom'

export default function StudentInternshipPage(): React.JSX.Element {
  /* The id of the Internship */
  const { id } = useParams()

  return (
    <StudentContent title="Famulatur Detailansicht">
      <>
        {/* // TODO: implement detail view */}
        <p>Famulatur mit ID {id}</p>
        <FamulenzHr />
        <p>Diese Seite ist im entstehen...</p>
      </>
    </StudentContent>
  )
}
