import InternshipOffersComponent from 'app/features/Organization/InternshipOffers/InternshipOffersComponent'
import FamulenzGridLayout from 'components/layout/GridLayout/FamulenzGridLayout/FamulenzGridLayout'
import { type IFamulenzGridLayoutElement } from 'components/layout/GridLayout/FamulenzGridLayoutElement/FamulenzGridLayoutElement'
import React from 'react'
import { type Layouts } from 'react-grid-layout'
import { BsMegaphone } from 'react-icons/bs'

export default function InternshipOffers(): React.JSX.Element {
  const layouts: Layouts = {
    xl: [{ i: 'internship-offers', x: 0, y: 0, w: 12, h: 10 }],
  }

  const elements: IFamulenzGridLayoutElement[] = [
    {
      i: 'internship-offers',
      element: <InternshipOffersComponent />,
      title: 'Alle Ausschreibungen',
      fullHeight: true,
    },
  ]

  return (
    <>
      <FamulenzGridLayout
        layouts={layouts}
        elements={elements}
        breadcrumbEntries={[
          {
            text: 'Ausschreibungen',
            to: '/organization/internship-offers',
            isRoot: true,
            icon: <BsMegaphone />,
          },
        ]}
      />
    </>
  )
}
