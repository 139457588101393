// rootReducer.js
import { combineReducers } from '@reduxjs/toolkit'
import userReducer from './features/User/UserSlice'
import apiReducer from './api/ApiReducer'

const rootReducer = combineReducers({
  api: apiReducer,
  user: userReducer,
})

export default rootReducer
