import React, { useEffect } from 'react'
import { Routes } from './routes'
import AOS from 'aos'

function App(): React.JSX.Element {
  /* Config for AOS */
  useEffect(() => {
    /* Init AOS */
    AOS.init({
      once: true,
      duration: 600,
      easing: 'ease-out-sine',
    })
  }, [])

  return <Routes />
}

export default App
