import { type IOrganizationalUnitMember } from '../OrganizationalUnitMember/OrganizationalUnitMember'

export enum OrganizationalUnitType {
  STATION = 1,
  HOUSE = 2,
  ORGANIZATION = 3,
}

export interface IOrganizationalUnitShort {
  id: number
  name: string
  parents?: IOrganizationalUnitShort[]
  ou_type: OrganizationalUnitType
}

export interface IOrganizationalUnit extends IOrganizationalUnitShort {
  description: string
  parents: IOrganizationalUnitShort[]
  created_at: string
  members: IOrganizationalUnitMember[]
}
