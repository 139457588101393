import type {
  IOrganizationInternshipOffersEditRequest,
  IOrganizationInternshipOffersEditResponse,
} from './organizationInternshipOffersEditInterface'
import createApiEndpoint from 'app/api/utils/createApiEndpoint'
import type { RootState } from 'app/store'
import type { IApiState } from 'app/api/models/ApiState'

/* Create selector */
const organizationInternshipOffersEditEndpointSelector = (
  state: RootState,
): IApiState<IOrganizationInternshipOffersEditResponse> =>
  state.api.organizationInternshipOffersEdit

/* Create reducer and thunk */
const {
  reducer: organizationInternshipOffersEditEndpointReducer,
  thunk: organizationInternshipOffersEditEndpointThunk,
} = createApiEndpoint<
  IOrganizationInternshipOffersEditRequest,
  IOrganizationInternshipOffersEditResponse
>({
  endpoint: '/internship-offers/{id}',
  method: 'PATCH',
  stateName: 'organizationInternshipOffersEdit',
})

/* Export reducer, selector and thunk action */
export {
  organizationInternshipOffersEditEndpointSelector,
  organizationInternshipOffersEditEndpointThunk,
}
export default organizationInternshipOffersEditEndpointReducer
