import FamulenzGridLayout from 'components/layout/GridLayout/FamulenzGridLayout/FamulenzGridLayout'
import { type IFamulenzGridLayoutElement } from 'components/layout/GridLayout/FamulenzGridLayoutElement/FamulenzGridLayoutElement'
import InternshipsTable from 'components/tables/InternshipsTable/InternshipsTable'
import React from 'react'
import { type Layouts } from 'react-grid-layout'
import { BsListUl } from 'react-icons/bs'

export default function Internships(): React.JSX.Element {
  const layouts: Layouts = {
    xl: [{ i: 'internships', x: 0, y: 0, w: 12, h: 10 }],
  }

  const elements: IFamulenzGridLayoutElement[] = [
    {
      i: 'internships',
      element: <InternshipsTable />,
      title: 'Stellenverwaltung',
      fullHeight: true,
    },
  ]

  return (
    <>
      <FamulenzGridLayout
        layouts={layouts}
        elements={elements}
        breadcrumbEntries={[
          {
            text: 'Stellenverwaltung',
            to: '/organization/internships',
            isRoot: true,
            icon: <BsListUl />,
          },
        ]}
      />
    </>
  )
}
