import React from 'react'
import styles from './FamulenzGridLayoutElement.module.scss'

export interface IFamulenzGridLayoutElement {
  i: string
  element: React.JSX.Element
  title?: string
  fullHeight?: boolean
}

export default function FamulenzGridLayoutElement(
  props: IFamulenzGridLayoutElement,
): React.JSX.Element {
  return (
    <div key={props.i} className={styles.container}>
      {props.title != null && (
        <div className={styles.titleContainer}>
          <span className={styles.title}>{props.title}</span>
        </div>
      )}
      <div className={styles.contentContainer}>{props.element}</div>
    </div>
  )
}
